import * as React from 'react';
import styles from './SearchResultItem.module.css';
import { Flex } from '@adobe/react-spectrum';

interface ISearchResultItemProps {
    html_id:string;
    image: string;
    title: string;
    subtitle: string;
    link: string;
    isFavorite: boolean;
    breadcrumbs: string[];
    onSearchSelected: (item:any) => void;
    isSelected: boolean;
    contextType: string;
    contextId?: number;
    stateName?: string;
    stateColor?: string;
}

const SearchResultItem: React.FunctionComponent<ISearchResultItemProps> = ({
    html_id,
    image,
    title,
    subtitle,
    link,
    isFavorite,
    breadcrumbs,
    onSearchSelected,
    isSelected,
    contextType,
    contextId,
    stateName,
    stateColor,
}) => {
    const handleClicked = () => { 
        onSearchSelected({link , contextType, contextId});
    }
    if(isSelected){
        document.getElementById(html_id)?.scrollIntoView({block: "center"});
    }
  return (
    <>
        <span onClick={handleClicked} id={html_id}>
            <Flex direction="row" gap="size-100" alignItems="center" UNSAFE_className={styles.itemContainer + " " + (isSelected? styles.itemContainer_active: '') }>
                <img src={image} alt="search result" className={styles.searchResultImage} />
                <Flex direction="column" gap="size-50" flex>
                    <div className={styles.searchResultTitle}>{title}</div>
                    <div className={styles.searchResultSubtitle}>{subtitle}</div>
                    <div className={styles.searchResultBreadcrumbs}>{breadcrumbs.join(' > ')}</div>
                </Flex>
                {stateName && (
                    <div className={styles.rightContainer}>
                        <div className={styles.searchResultIndicator} style={{backgroundColor: stateColor}}></div>
                        <div className={styles.searchResultStatus}>{stateName}</div>
                    </div>
                )}
            </Flex>
        </span>
    </>
  );
};

export default SearchResultItem;
