import { ServiceBase } from "../ServiceBase";
import { IContactDataTransferRequest } from "./requests/ContactDataTransferRequest";
import { ILoginRequest } from "./requests/LoginRequest";
import { ContactDataTransferResponse } from "./responses/ContactDataTransferResponse";
import { LoginResponse } from "./responses/LoginResponse";
import { MAIN } from "../../../utils/ServiceUtils";
import { ILogoutRequest } from "./requests/LogoutRequest";
import { LogoutResponse } from "./responses/LogoutResponse";
import { IGetContactRequest } from "./requests/GetContactRequest";
import { GetContactResponse } from "./responses/GetContactResponse";
import { IListDistributionGroupRequest } from "./requests/ListDistributionGroupRequest";
import { ListDistributionGroupResponse } from "./responses/ListDistributionGroupResponse";
import { IGetContactListRequest } from "./requests/GetContactListRequest";
import { GetContactListResponse } from "./responses/GetContactListResponse";
import { IListReferenceRequest } from "./requests/ListReferenceRequest";
import { ListReferenceResponse } from "./responses/ListReferenceResponse";

export interface IMainService{
    login(request:ILoginRequest):Promise<LoginResponse>;
    logout(request:ILogoutRequest):Promise<LogoutResponse>;
    contactDataTransfer(request: IContactDataTransferRequest): Promise<ContactDataTransferResponse>;
    getContact(request: IGetContactRequest): Promise<GetContactResponse>;
    listDistributionGroup(request: IListDistributionGroupRequest): Promise<ListDistributionGroupResponse>;
    getContactList(request: IGetContactListRequest): Promise<GetContactListResponse>;
    listReference(request: IListReferenceRequest): Promise<ListReferenceResponse>;
}

export class MainService extends ServiceBase implements IMainService {
    // constructor(_url:string){
    //     super(_url);
    // }
    _addRootElementForDGroupList(xml:string):string{
        xml = xml.replace('</count><DGROUP>', '</count><DGROUPS><DGROUP>');
        xml = xml.replace('</DGROUP></ns1:ListDistributionGroupResponse>', '</DGROUP></DGROUPS></ns1:ListDistributionGroupResponse>');
        return xml;
    }

    _addRootElementForContactList(xml:string):string{
        xml = xml.replace('</count><CONTACT>', '</count><CONTACTS><CONTACT>');
        xml = xml.replace('</CONTACT></ns1:GetContactListResponse>', '</CONTACT></CONTACTS></ns1:GetContactListResponse>');
        return xml;
    }

    _addRootElementForReferenceList(xml:string):string{
        xml = xml.replace('</count><REFERENCE>', '</count><LIST><REFERENCE>');
        xml = xml.replace('</REFERENCE></ns1:ListReferenceResponse>', '</REFERENCE></LIST></ns1:ListReferenceResponse>');
        return xml;
    }
    public async login(request:ILoginRequest):Promise<LoginResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.login, request.cancelSource)).data ;
            await this.updateCache(MAIN.login, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(MAIN.login, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).LoginResponse as LoginResponse;
        super._checkErrors(parsedResponse);
        return parsedResponse;
    }

    public async logout(request:ILogoutRequest):Promise<LogoutResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.logout, request.cancelSource)).data ;
            await this.updateCache(MAIN.logout, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(MAIN.logout, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).LogoutResponse as LogoutResponse;
        super._checkErrors(parsedResponse);
        return parsedResponse;
    }

    public async contactDataTransfer(request:IContactDataTransferRequest):Promise<ContactDataTransferResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.contactDataTransfer, request.cancelSource)).data ;
            await this.updateCache(MAIN.contactDataTransfer, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.contactDataTransfer, request.toXml());
        }
        
        var parsedResponse = super.sendResponse(response).ContactDataTransferResponse as ContactDataTransferResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async getContact(request:IGetContactRequest):Promise<GetContactResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.getContact, request.cancelSource)).data ;
            await this.updateCache(MAIN.getContact, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.getContact, request.toXml());
        }
        
        var parsedResponse = super.sendResponse(response).GetContactResponse as GetContactResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async listDistributionGroup(request:IListDistributionGroupRequest):Promise<ListDistributionGroupResponse>{
        let response:any = null;
        try {
            var tmp_response = (await this.makeCall(request.toXml(), MAIN.listDistributionGroup, request.cancelSource)).data ;
            response = this._addRootElementForDGroupList(tmp_response.data);
            await this.updateCache(MAIN.listDistributionGroup, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.listDistributionGroup, request.toXml());
        }
        
        var parsedResponse = super.sendResponse(response).ListDistributionGroupResponse as ListDistributionGroupResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async getContactList(request:IGetContactListRequest):Promise<GetContactListResponse>{
        let response:any = null;
        try {
            var tmp_response = (await this.makeCall(request.toXml(), MAIN.getContactList, request.cancelSource)).data ;
            response = this._addRootElementForContactList(tmp_response);
            await this.updateCache(MAIN.getContactList, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.getContactList, request.toXml());
        }
        
        var parsedResponse = super.sendResponse(response).GetContactListResponse as GetContactListResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listReference(request:IListReferenceRequest):Promise<ListReferenceResponse>{
        let response:any = null;
        try {
            var tmp_response = (await this.makeCall(request.toXml(), MAIN.listReference, request.cancelSource)).data ;
            response = this._addRootElementForReferenceList(tmp_response);
            await this.updateCache(MAIN.listReference, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.listReference, request.toXml());
        }
        
        var parsedResponse = super.sendResponse(response).ListReferenceResponse as ListReferenceResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }
}