import { useCallback, useEffect, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListStatusRequest } from '../../../../services/soap/project/requests/ListStatusRequest';
import { Flex, View } from '@adobe/react-spectrum';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RightArrowIcon, AtollonIcon, PrintIcon, EditIcon, ToolIcon, TrashIcon, MoreIcon, ProjectIconBlue } from '../../Icons/IconsLib';
import styles from './HeaderToolbarComponent.module.css';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import ButtonsComponent from './ButtonsComponent';

interface IProjectComponentProps {
  headerData: any;
  statusColor: (color: string) => string;
  onPrintClicked?: () => void;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;
  onToolClicked?: () => void;
  onAIClicked?: () => void;
}

const ProjectComponent = ({ headerData, statusColor, onPrintClicked, onEditClicked, onDeleteClicked, onToolClicked, onAIClicked }: IProjectComponentProps) => {
  const { store, projectService } = useDependency();
  const [projectStatusList, setProjectStatusList] = useState<any>([]);
  const [fullPath, setFullPath] = useState<string>('');
  const [projectTypeName, setProjectTypeName] = useState<string>('');
  useEffect(() => {
    (async () => {
      headerData && (await getFullPath());
      await getProjectStatusArray();
    })();
  }, []);

  const getFullPath = async () => {
    const response: any = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, headerData?.id || 0));
    //console.log('response.LIST[0].typeName', response.LIST[0].CONTEXT.typeName);
    setProjectTypeName(response.LIST[0].CONTEXT?.typeName);
    const modifiedList = response.LIST.slice(1).reverse();
    //console.log('getFullPath response', response);
    setFullPath(modifiedList.map((item: any) => item.CONTEXT.name).join(' > '));
  };

  const getProjectStatusArray = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await projectService.listProjectStatus(new ListStatusRequest(store.Server, store.SessionId, 1, 'com.atollon.project.fpatypecategory.recruitment.placement'));

    if (res.result === 'OK' && res.STATUS_LIST.length > 0) {
      // const projectStatusColor: any = res.STATUS_LIST.reduce((acc: any, val: any) => ({ ...acc, [val.STATUS.id]: { color: val.STATUS.color, name: val.STATUS.name } }), {});
      //console.log('projectStatusColor', projectStatusColor);

      // let projectStatusPriorty = res.STATUS_LIST.reduce((acc: any, val: any) => ({ ...acc, [val.STATUS.name]: { priority: val.STATUS.priority } }), {});

      res?.STATUS_LIST.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name, color } = status.STATUS;
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name, color });
      });
      //console.log('statusArray', statusArray);
      setProjectStatusList(statusArray);
    }
  }, []);

  function itemTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5}>{data.name}</View>
      </Flex>
    );
  }

  function valueTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5} UNSAFE_style={{ marginLeft: '10px' }}>
          <span title={data?.name}>{data?.name && data?.name.length > 16 ? data?.name.substring(0, 16) + '...' : data?.name}</span>
        </View>
      </Flex>
    );
  }

  return (
    <Flex width="100%">
      <View UNSAFE_className={styles.header_main}>
        <View UNSAFE_className={styles.header_child}>
          <View UNSAFE_className={styles.frame_parent}>
            <View UNSAFE_className={styles.icon_activity_parent}>
              <ProjectIconBlue size={32} />
              <b title={headerData?.name} className={styles.activity_name}>
                {headerData?.name.length > 55 ? headerData?.name.substring(0, 55) + '...' : headerData?.name}
              </b>
            </View>

            <View UNSAFE_className={styles.folder_name_parent}>
              <View UNSAFE_className={styles.folder_name}>{fullPath}</View>
            </View>
          </View>
          <View UNSAFE_className={styles.tag_parent} marginTop={20}>
            <View UNSAFE_className={styles.tag}>
              <View UNSAFE_className={styles.activity_type}>
                <View UNSAFE_className={styles.activity_type_text}>{projectTypeName}</View>

                {headerData?.refId ? (
                  <>
                    <View UNSAFE_className={styles.divider}></View> <View UNSAFE_className={styles.ref_id}>{headerData?.refId}</View>
                  </>
                ) : (
                  <View UNSAFE_className={styles.divider_transparent}></View>
                )}
              </View>
            </View>
          </View>
        </View>
        <View UNSAFE_className={styles.icon_list_parent}>
          <DropDownListComponent
            width={'200px'}
            dataSource={projectStatusList}
            fields={{ text: 'name', value: 'id' }}
            showClearButton={true}
            itemTemplate={itemTemplate}
            valueTemplate={valueTemplate}
            value={headerData?.customState}
          />

          <ButtonsComponent
            styles={styles}
            AtollonIcon={AtollonIcon}
            PrintIcon={PrintIcon}
            EditIcon={EditIcon}
            ToolIcon={ToolIcon}
            TrashIcon={TrashIcon}
            onPrintClicked={onPrintClicked}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked}
            onToolClicked={onToolClicked}
            onAIClicked={onAIClicked}
          />
        </View>
      </View>
    </Flex>
  );
};

export default ProjectComponent;
