import * as React from 'react';
import { Flex } from '@react-spectrum/layout';
import { View } from '@react-spectrum/view';
import styles from './EmptyComponent.module.css';
import { EmptyIcon2 } from '../Icons/IconsLib';
import { useReefConfig } from '../../../hooks/UseReefConfig';
export interface IEmptyComponentProps {
    url: string;
    title: string;
}

export function EmptyComponent ({url, title}: IEmptyComponentProps) {
    const { install_path } = useReefConfig();
    const handleOpenInDesktop = () => {
        window.open(url, '_blank');
    };
    const handleInstallDesktop = () => {
        window.open(install_path, '_blank');
    };
  return (
    <>
    <Flex UNSAFE_className={styles.container} direction="column" gap="size-100" justifyContent={'center'} alignItems={'center'}>
        <View>
            <EmptyIcon2 size={40} />
        </View>
        <View UNSAFE_className={styles.mainTitle}>
            {title}
        </View>
        <View UNSAFE_className={styles.subTitle}>
            This component is currently unavailable. Please check it out in the desktop version.
        </View>
        <View UNSAFE_className={styles.subTitle} height={'size-100'} />
        <Flex direction={{M:"row",base:"column"}} gap={{base:'size-300'}} justifyContent={'space-evenly'} maxWidth={'400px'}>
            <View>
                <span className={styles.openButton} onClick={handleOpenInDesktop}>
                    Open in desktop app
                </span>
            </View>
            <View>
                <span className={styles.installButton} onClick={handleInstallDesktop}>Install desktop app</span>
            </View>
        </Flex>
    </Flex>
</>
);
}
