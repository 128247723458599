import {parseAbsoluteToLocal} from '@internationalized/date';
import { Component } from './FormDefinitions';
import { ComboBox, DatePicker, Flex, Item, NumberField, TextArea, TextField, View, Section, Key } from '@adobe/react-spectrum';
import { useEffect, useState } from 'react';

export interface IFormControlProps {
    component: Component;
    data: Map<string, any>;
    updateData: (key: string, value: any) => void;
}
const CONTROLS = {
    com:{
        atollon:{
            formDesigner:{
                components:{
                    MainContentGroup: "com.atollon.formDesigner.components::MainContentGroup",
                    VBoxExtended: "com.atollon.formDesigner.components::VBoxExtended",
                    HBoxExtended: "com.atollon.formDesigner.components::HBoxExtended",
                    inputs: {
                        FormTextInput: "com.atollon.formDesigner.components.inputs::FormTextInput",
                        FormDateField: "com.atollon.formDesigner.components.inputs::FormDateField",
                        FormNumberInput: "com.atollon.formDesigner.components.inputs::FormNumberInput",
                        FormComboBox: "com.atollon.formDesigner.components.inputs::FormComboBox",
                        FormTextArea: "com.atollon.formDesigner.components.inputs::FormTextArea",
                    }
                }
            }
        }
    }
};
export function FormControl ({ component, data, updateData }: IFormControlProps) {
    // console.log('FormControl', component);
    switch(component.CLASS) {
        case CONTROLS.com.atollon.formDesigner.components.MainContentGroup:
            return (<MainContentGroup component={component} data={data} updateData={updateData} />);
        case CONTROLS.com.atollon.formDesigner.components.VBoxExtended:
            return (<VBoxExtended component={component} data={data} updateData={updateData} />);
        case CONTROLS.com.atollon.formDesigner.components.HBoxExtended:
            return (<HBoxExtended component={component} data={data} updateData={updateData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormTextInput:
            return (<FormTextInput component={component} data={data} updateData={updateData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormDateField:
            return (<FormDateField component={component} data={data} updateData={updateData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormNumberInput:
            return (<FormNumberInput component={component} data={data} updateData={updateData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormComboBox:
            return (<FormComboBox component={component} data={data} updateData={updateData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormTextArea:
            return (<FormTextArea component={component} data={data} updateData={updateData} />);
        default:
            return (<UnSupportedComponent component={component} data={data} updateData={updateData} />);
    }
}

function MainContentGroup( { component, data, updateData }: IFormControlProps) {

    return (
        <View>
            {component.CHILDREN && component.CHILDREN.COMPONENT.map((child, index) => (<FormControl key={index} component={child} data={data} updateData={updateData} />))}
        </View>
    );
}

function VBoxExtended( { component, data, updateData }: IFormControlProps) {

    return (
        <Flex direction="column" gap={10}>
            {component.CHILDREN && component.CHILDREN.COMPONENT.map((child, index) => (<FormControl key={index} component={child} data={data} updateData={updateData} />))}
        </Flex>
    );
}

function HBoxExtended( { component, data, updateData }: IFormControlProps) {

    return (
        <Flex direction={{ "base": 'column', "M": "row"}} gap={10}>
            {component.CHILDREN && component.CHILDREN.COMPONENT.map((child, index) => (<FormControl key={index} component={child} data={data} updateData={updateData} />))}
        </Flex>
    );
}

function UnSupportedComponent( { component, data, updateData }: IFormControlProps) {
    return (
        <View>
            {component.CHILDREN && component.CHILDREN.COMPONENT.map((child, index) => (<FormControl key={index} component={child} data={data} updateData={updateData} />))}
        </View>
    );
}

function FormTextInput( { component, data, updateData }: IFormControlProps) {
    return (
        <View>
            <TextField label={component.PROPERTIES.name} 
                        width={'100%'}
                        value={data.get(component.FORMITEM) ? data.get(component.FORMITEM).value : ''} 
                        defaultValue={component.PROPERTIES.value}
                        onChange={(value) => updateData(component.FORMITEM, value)}
                        />
        </View>
    );
}

function FormDateField( { component, data, updateData }: IFormControlProps) {
    return (
        <View>
            <DatePicker label={component.PROPERTIES.name} 
                        width={'100%'}
                        granularity="day" 
                        defaultValue={parseAbsoluteToLocal(component.PROPERTIES.value ? component.PROPERTIES.value+'Z': new Date().toISOString())}
                        onChange={(value) => updateData(component.FORMITEM, value)}
                        value={parseAbsoluteToLocal(data.get(component.FORMITEM) && data.get(component.FORMITEM).value ? data.get(component.FORMITEM).value+'Z': new Date().toISOString())}
                        />
        </View>
    );
}

function FormNumberInput( { component, data, updateData }: IFormControlProps) {
    return (
        <View>
            <NumberField label={component.PROPERTIES.name} 
                        width={'100%'}
                        value={+(data.get(component.FORMITEM) ? data.get(component.FORMITEM).value : 0)} 
                        defaultValue={+component.PROPERTIES.value!} 
                        onChange={(value) => updateData(component.FORMITEM, value)}
                        />
        </View>
    );
}

function FormComboBox( { component, data, updateData }: IFormControlProps) {

    return (
        <View>
            <ComboBox label={component.PROPERTIES.name} 
                        width={'100%'}
                        defaultSelectedKey={component.PROPERTIES.value}
                        selectedKey={data.get(component.FORMITEM) ? data.get(component.FORMITEM).value : ''} 
                        onSelectionChange={(key) => updateData(component.FORMITEM, key)}
                        >
                {component.PROPERTIES.dataProvider!.items!.item!.map((v, i) => (<Item key={v}>{v}</Item>))}
            </ComboBox>
        </View>
    );
}

function FormTextArea( { component, data, updateData }: IFormControlProps) {
    return (
        <View>
            <TextArea label={component.PROPERTIES.name} 
                        width={'100%'}
                        value={data.get(component.FORMITEM).value} 
                        defaultValue={component.PROPERTIES.value} 
                        onChange={(value) => updateData(component.FORMITEM, value)}
                        />
        </View>
    );
}