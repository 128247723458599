import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Project, toProjectXml } from '../responses/Folder';

export interface IUpdateProjectRequest extends IBaseRequest {
    project: Project;
}

export class UpdateProjectRequest extends BaseRequest implements IUpdateProjectRequest {
    public project: Project;
    constructor(server: string, session: string, project: Project) {
        super(server, session);
        this.project = project;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += toProjectXml(this.project);
        return super.wrapperXml('UpdateProject', parameters);
    }
}
