import {ServiceBase} from '../ServiceBase';
import { FEATURES } from '../../../utils/ServiceUtils';
import { IListApplicationsRequest } from './requests/ListApplicationsRequest';
import { ListApplicationsResponse } from './responses/ListApplicationsResponse';
import { UpdateRegisterResponse } from './responses/UpdateRegisterResponse';
import { IUpdateRegisterRequest } from './requests/UpdateRegisterRequest';
import { ListRegisterResponse } from './responses/ListRegisterResponse';
import { IListRegisterRequest } from './requests/ListRegisterRequest';
import { ICreateRegisterRequest } from './requests/CreateRegisterRequest';
import { CreateRegisterResponse } from './responses/CreateRegisterResponse';
import { IGetFormPanelRequest } from './requests/GetFormPanelRequest';
import { GetFormPanelResponse } from './responses/GetFormPanelResponse';
export interface IFeatureService {
    listApplications(request:IListApplicationsRequest):Promise<ListApplicationsResponse>;
    updateRegister(request:IUpdateRegisterRequest):Promise<UpdateRegisterResponse>;
    listRegister(request:IListRegisterRequest):Promise<ListRegisterResponse>;
    createRegister(request:ICreateRegisterRequest):Promise<CreateRegisterResponse>;
    getFormPanel(request: IGetFormPanelRequest): Promise<GetFormPanelResponse>;
}

export class FeatureService extends ServiceBase implements IFeatureService { 
    private _addRootElementForApplicationList(xml:string):string{
        xml = xml.replace('</count><APPLICATION>', '</count><APPLICATIONS><APPLICATION>');
        xml = xml.replace('</APPLICATION></ns1:ListApplicationsResponse>', '</APPLICATION></APPLICATIONS></ns1:ListApplicationsResponse>');
        return xml;
    }
    
    private _addRootElementForRegisterList(xml:string):string{
        xml = xml.replace('</count><ROW>', '</count><ROWS><ROW>');
        xml = xml.replace('</ROW></ns1:ListRegisterResponse>', '</ROW></ROWS></ns1:ListRegisterResponse>');
        return xml;
    }

    public async listApplications(request: IListApplicationsRequest): Promise<ListApplicationsResponse>{
        let response:any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FEATURES.listApplications, request.cancelSource));
            response = this._addRootElementForApplicationList(tmp_response.data);
            await this.updateCache(FEATURES.listApplications, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.listApplications, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListApplicationsResponse as ListApplicationsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
 
    public async updateRegister(request: IUpdateRegisterRequest): Promise<UpdateRegisterResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.updateRegister, request.cancelSource)).data ;
            await this.updateCache(FEATURES.updateRegister, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.updateRegister, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateRegisterResponse as UpdateRegisterResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listRegister(request: IListRegisterRequest): Promise<ListRegisterResponse>{
        let response:any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FEATURES.listRegister, request.cancelSource)).data;
            response = this._addRootElementForRegisterList(tmp_response);
            await this.updateCache(FEATURES.listRegister, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.listRegister, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListRegisterResponse as ListRegisterResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async createRegister(request: ICreateRegisterRequest): Promise<CreateRegisterResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.createRegister, request.cancelSource)).data ;
            await this.updateCache(FEATURES.createRegister, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.createRegister, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).CreateRegisterResponse as CreateRegisterResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async getFormPanel(request: IGetFormPanelRequest): Promise<GetFormPanelResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FEATURES.getFormPanel, request.cancelSource)).data ;
            await this.updateCache(FEATURES.getFormPanel, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FEATURES.getFormPanel, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetFormPanelResponse as GetFormPanelResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
}