import { useCallback, useEffect, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListStatusRequest } from '../../../../services/soap/project/requests/ListStatusRequest';
import { Flex, View } from '@adobe/react-spectrum';
import {
  AtollonIcon,
  PrintIcon,
  EditIcon,
  ToolIcon,
  TrashIcon,
  MoreIcon,
  MassmailGreen,
  MassmailRed,
  TwitterIcon,
  FacebookIcon,
  LinkdinIcon,
  ClientIconBlue,
  RightArrowIcon,
} from '../../../../components/protected/Icons/IconsLib';
import styles from './HeaderToolbarComponent.module.css';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import ButtonsComponent from './ButtonsComponent';

interface IFolderComponentProps {
  headerData: any;
  statusColor: (color: string) => string;
  onPrintClicked?: () => void;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;
  onToolClicked?: () => void;
  onAIClicked?: () => void;
}

const FolderComponent = ({ headerData, statusColor, onPrintClicked, onEditClicked, onDeleteClicked, onToolClicked, onAIClicked }: IFolderComponentProps) => {
  const { store, projectService } = useDependency();
  const [folderStatusList, setFolderStatusList] = useState<any>([]);
  const [fullPath, setFullPath] = useState<string>('');

  useEffect(() => {
    (async () => {
      headerData && (await getFullPath());
      await getFolderStatusArray();
    })();
  }, []);

  const getFolderStatusArray = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await projectService.listFolderStatus(new ListStatusRequest(store.Server, store.SessionId, 0));

    if (res.result === 'OK' && res.STATUS_LIST.length > 0) {
      // const folderStatusColor: any = res.STATUS_LIST.reduce((acc: any, val: any) => ({ ...acc, [val.STATUS.id]: { color: val.STATUS.color, name: val.STATUS.name } }), {});

      // let folderStatusPriorty = res.STATUS_LIST.reduce((acc: any, val: any) => ({ ...acc, [val.STATUS.name]: { priority: val.STATUS.priority } }), {});

      res?.STATUS_LIST.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name, color } = status.STATUS;
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name, color });
      });
      //console.log('FolderStatusArray', statusArray);
      setFolderStatusList(statusArray);
    }
    return statusArray;
  }, []);

  const getFullPath = async () => {
    const response = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, headerData?.id || 0));
    setFullPath(response.LIST.map(item => item.CONTEXT.name).join(' > '));
  };

  const getAddress = () => {
    return headerData?.CONTACT?.COMPANY?.street !== undefined && headerData?.CONTACT?.COMPANY?.street !== null
      ? headerData?.CONTACT?.COMPANY?.street + ' ' + headerData?.CONTACT?.COMPANY?.city + ' ' + headerData?.CONTACT?.COMPANY?.zip + ' ' + headerData?.CONTACT?.COMPANY?.country
      : '';
  };

  function itemTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5}>{data.name}</View>
      </Flex>
    );
  }

  function valueTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5} UNSAFE_style={{ marginLeft: '10px' }}>
          <span title={data?.name}>{data?.name && data?.name.length > 16 ? data?.name.substring(0, 16) + '...' : data?.name}</span>
        </View>
      </Flex>
    );
  }

  return (
    <Flex width="100%">
      <View UNSAFE_className={styles.header_main}>
        <View UNSAFE_className={styles.header_child}>
          <View UNSAFE_className={styles.frame_parent}>
            <View UNSAFE_className={styles.icon_activity_parent}>
              <ClientIconBlue size={32} />
              <b title={headerData?.name} className={styles.activity_name}>
                {headerData?.name.length > 45 ? headerData?.name.substring(0, 45) + '...' : headerData?.name}
              </b>
            </View>
            <View UNSAFE_className={styles.folder_name_parent}>
              <View UNSAFE_className={styles.folder_name}>{fullPath}</View>
              {/* <View UNSAFE_className={styles.folder_name}>Folder Name</View>

              <RightArrowIcon size={32} />
              <View UNSAFE_className={styles.sub_folder_name}>SUB FOLDER NAME</View> */}
            </View>
          </View>
          <Flex direction={'row'} justifyContent={'space-between'} UNSAFE_className={styles.tag_parent} marginTop={20}>
            <View UNSAFE_style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
              <View UNSAFE_className={styles.tag}>
                <View UNSAFE_className={styles.activity_type}>
                  <View UNSAFE_className={styles.activity_type_text}>{headerData?.folderTypeName}</View>

                  {headerData?.referenceId ? (
                    <>
                      <View UNSAFE_className={styles.divider}></View> <View UNSAFE_className={styles.ref_id}>{headerData?.referenceId}</View>
                    </>
                  ) : (
                    <View UNSAFE_className={styles.divider_transparent}></View>
                  )}
                </View>
              </View>

              <View UNSAFE_className={styles.io_parent}>
                {headerData?.CONTACT?.COMPANY?.COMPANYINFO.regNumber !== undefined ? (
                  <>
                    <View UNSAFE_className={styles.io_label}>IČO</View>
                    <View UNSAFE_className={styles.io_text}>{headerData?.CONTACT?.COMPANY?.COMPANYINFO.regNumber}</View>{' '}
                  </>
                ) : (
                  ''
                )}
              </View>

              <View UNSAFE_className={styles.di_parent}>
                {headerData?.CONTACT?.COMPANY?.COMPANYINFO.VATNumber !== undefined ? (
                  <>
                    <View UNSAFE_className={styles.di_label}>DIČ</View>
                    <View UNSAFE_className={styles.di_text}>{headerData?.CONTACT?.COMPANY?.COMPANYINFO.VATNumber}</View>
                  </>
                ) : (
                  ''
                )}
              </View>

              <View UNSAFE_className={styles.address_parent}>
                {getAddress() !== '' ? (
                  <>
                    <View UNSAFE_className={styles.address_child}>Address</View>
                    <View UNSAFE_className={styles.address}>{getAddress()}</View>
                  </>
                ) : (
                  ''
                )}
              </View>
            </View>

            <View UNSAFE_className={styles.socials_icons}>
              <View UNSAFE_className={styles.icon_twitter}>
                <TwitterIcon size={24} />
              </View>

              <View UNSAFE_className={styles.icon_facebook}>
                <FacebookIcon size={24} />
              </View>

              <View UNSAFE_className={styles.icon_linkedin}>
                <LinkdinIcon size={24} />
              </View>
            </View>
          </Flex>
        </View>
        <View UNSAFE_className={styles.icon_list_parent}>
          {headerData?.CONTACT?.COMPANY?.adsAllowed === 'N' ? (
            <View UNSAFE_className={styles.ad_not_allowed}>
              <MassmailRed size={20} />
            </View>
          ) : (
            <View UNSAFE_className={styles.ad_allowed}>
              <MassmailGreen size={20} />
            </View>
          )}
          {/* <ComboBox defaultItems={folderStatusList} selectedKey={folderStatus} onSelectionChange={() => setFolderStatus}>
            {(item: any) => <Item>{item.name}</Item>}
          </ComboBox> */}

          <DropDownListComponent
            width={'200px'}
            name="vacancyTypeReef"
            dataSource={folderStatusList}
            fields={{ text: 'name', value: 'id' }}
            showClearButton={true}
            itemTemplate={itemTemplate}
            valueTemplate={valueTemplate}
            value={headerData?.customState}
          />

          <ButtonsComponent
            styles={styles}
            AtollonIcon={AtollonIcon}
            PrintIcon={PrintIcon}
            EditIcon={EditIcon}
            ToolIcon={ToolIcon}
            TrashIcon={TrashIcon}
            onPrintClicked={onPrintClicked}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked}
            onToolClicked={onToolClicked}
            onAIClicked={onAIClicked}
          />
        </View>
      </View>
    </Flex>
  );
};

export default FolderComponent;
