import { FunctionComponent, KeyboardEvent, useState } from "react";
import { 
    Button, 
    ButtonGroup, 
    Checkbox, 
    Content, 
    Dialog, 
    Divider, 
    Form, 
    Heading, 
    TextField, 
    useDialogContainer
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useNavigate } from "react-router-dom";
import { doLogin } from "../../../utils/LoginUtils";
import { useDependency } from "../../../contexts/DependencyProvider";
import { useReefConfig } from "../../../hooks/UseReefConfig";


const md5 = require("md5");

const LoginDialog: FunctionComponent = () => {
    let dialog = useDialogContainer();
    const { mainService, store } = useDependency();
    const navigate = useNavigate();
    const { loginInfo } = useReefConfig();
    const [username, setUsername] = useState<string>(store.Username);
    const [password, setPassword] = useState<string>();
    const [server, setServer] = useState<string>(store.Server);
    const [rememberMe, setRememberMe] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    

    const handleLogin = async () => {
        try {
            setIsDisabled(true);
            if (username === "" || password === "" || server === "") {
              ToastQueue.negative("Please fill in all fields!");
              return;
            }
            var p_username:string = username;
            if(!username.includes('@')) {
              if(loginInfo.instanceName !== '' && loginInfo.instanceName !== null)
                p_username += '@' + loginInfo.instanceName;
              else if(server !== '' && server !== null)
                p_username += '@' + server;
            }
            const { status, message, session } = await doLogin(p_username, md5(password), server, mainService, store);

            if (status) {
              if(rememberMe){
                store.Username = username;
                store.Password = md5(password);
              }
              store.Server = server;
              ToastQueue.positive("Login is done!", {timeout: 3000});
              dialog.dismiss();
              navigate("/user/dashboard", { state: { session } });
            } else {
              ToastQueue.negative(message, {timeout: 3000});
            }        
          } catch (error) {
            console.log('error in handleLogin', error);
          } finally {
            setIsDisabled(false);      
          }      
    }
    const onHandleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleLogin();
      }
    }
  
    return(<>
        <Dialog>
            <Heading>Session Expired. Login again</Heading>
            <Divider />
            <Content height={200}>
                <Form labelPosition="side" width="100%">
                    <TextField autoFocus 
                            isRequired
                            label="Username" 
                            aria-label="Username"
                            value={username}
                            onChange={setUsername} 
                            onKeyUp={onHandleKeyUp}
                            isDisabled={true}
                            />
                    <TextField isRequired
                            type="password"
                            label="Password" 
                            aria-label="Password"
                            value={password}
                            onChange={setPassword}
                            onKeyUp={onHandleKeyUp}
                            isDisabled={isDisabled}
                            />        
                    <TextField isRequired
                            label="Server"
                            aria-label="Server"
                            value={server}
                            onKeyUp={onHandleKeyUp}
                            onChange={setServer}
                            isDisabled={true}
                            isHidden={!loginInfo.showServer}
                            />
                    <Checkbox 
                      isDisabled={isDisabled}
                      isSelected={rememberMe}
                      onChange={setRememberMe}
                    >Remember me</Checkbox>

                </Form>
            </Content>
            <ButtonGroup>
                <Button variant="secondary" onPress={dialog.dismiss}>Cancel</Button>
                <Button variant="accent" onPress={handleLogin}>Login</Button>
            </ButtonGroup>
        </Dialog>
    </>)
}

export default LoginDialog;
