import { LayoutComponent } from '../LayoutComponent';
import { Flex, Link, ProgressCircle, TextField, View } from '@adobe/react-spectrum';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { TrashIcon } from '../../Icons/IconsLib';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { PrintContextRequest } from '../../../../services/rest/print/requests/PrintContextRequest';
import useComponentReload from '../../../../hooks/UseComponentReload';
import { UpdateActivityRequest } from '../../../../services/soap/project/requests/UpdateActivityRequest';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { ToastQueue } from '@react-spectrum/toast';
import { useReefConfig } from '../../../../hooks/UseReefConfig';
import SignatureCanvas from 'react-signature-canvas';
import styles from './SignComponent.module.css';

export interface ISignComponentProps {
  // signatureBase64?: string;
  // isSignatureRequired?: boolean;
  // minHeight?: number;
  // percentWidth?: number;
  readonly signatureColor?: number;
  readonly signatureSize?: number;
  readonly signedStatusId?: string;
}

function Signature({ signatureColor, signatureSize, signedStatusId }: ISignComponentProps) {
  //console.log('signedStatusId', signedStatusId);
  const { baseUrl, loginInfo } = useReefConfig();
  const { isMobile } = useViewInfo();
  const [reloadComponent] = useComponentReload();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [signBase64, setSignBase64] = useState<any>('');
  const [fullName, setFullName] = useState<string>('');
  const [signatureKey, setSignatureKey] = useState(Date.now());
  const { printRestService, projectService, store } = useDependency();
  const selectedItem = useSelector((state: any) => state.finder.selectedItem);
  const sigCanvas = useRef<SignatureCanvas>(null);
  useEffect(() => {
    //console.log('loginInfo', loginInfo);
    reloadSignatureComponent();
  }, [selectedItem, reloadComponent]);

  function statusColor(color: string) {
    if (!color) return '#000000';
    return '#' + ('00000' + parseInt(color).toString(16)).slice(-6);
  }

  const reloadSignatureComponent = () => {
    setSignatureKey(Date.now());
  };

  const onChange = () => {
    let signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    signatureData = signatureData?.replace(/^data:image\/png;base64,/, '');
    setSignBase64(signatureData);
  };

  const handleConfirm = () => {
    setShowLoader(true);

    let windowReference: any = window.open('', '_blank', 'height=800,width=1200,scrollbars=yes,status=yes,menubar=no,toolbar=no');
    printRestService
      .printDocument(new PrintContextRequest(store.Server, store.SessionId, 'activity', String(selectedItem?.id), 'pdf', fullName, signBase64))
      .then(async (response: any) => {
        //console.log('response', response.documentId);

        let strURL = `${baseUrl}/asp-cgi/doc-download.cgi?instance=${loginInfo.instanceName}&session=${store.SessionId}&documentId=${response.documentId}&disposition=inline`;

        windowReference?.location.replace(strURL);

        const { ACTIVITY } = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, selectedItem.id));
        if (ACTIVITY.customState !== signedStatusId) {
          const { result } = await projectService.updateActivity(new UpdateActivityRequest(store.Server, store.SessionId, { ...ACTIVITY, customState: signedStatusId }));
          if (result !== 'OK') ToastQueue.negative('Error updating activity status');
        }

        setFullName('');
        setSignBase64('');
        setShowLoader(false);
      })
      .catch(error => {
        console.log('error', error);
        setShowLoader(false);
        windowReference?.close();
      });
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignBase64('');
  };

  if (showLoader) {
    return (
      <Flex width="100%" minHeight={'50px'} justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} gap={'13px'} UNSAFE_className={styles.signature_parent_grid} width={'100%'}>
        <View UNSAFE_className={styles.signature_heading}>Signature</View>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.signature_sub_heading}>
          <View UNSAFE_className={styles.signature_sub_heading_text}>Before signing the document, fill in your name and surname below.</View>
        </Flex>
        <Flex direction={'row'} UNSAFE_className={styles.signature_field_wrapper} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'}>
          <View UNSAFE_className={styles.signature_field}></View>
        </Flex>
        <Flex direction={'row'} UNSAFE_className={styles.signature_parent} alignItems={'start'} alignSelf={'stretch'} justifyContent={'space-between'} gap={'20px'}>
          <Flex direction={'column'} UNSAFE_className={styles.full_name} alignItems={'start'} justifyContent={'start'}>
            <Flex direction={'row'} UNSAFE_className={styles.label_fullname_container} alignItems={'center'} justifyContent={'start'} gap={'4px'}>
              <View UNSAFE_className={styles.label_fullname}>Full name</View>
            </Flex>
            <View>
              <TextField width={'256px'} height={'32px'} onChange={val => setFullName(val)}></TextField>
            </View>
          </Flex>
          <Flex direction={'column'} UNSAFE_className={styles.button_wrapper_delete} alignItems={'start'} justifyContent={'start'}>
            <Flex direction={'row'} alignItems={'start'} alignSelf={'stretch'} gap={'8px'}>
              <div className={styles.icon_trash} onClick={clearSignature}>
                <TrashIcon size={32} />
              </div>
              <Flex direction={'column'} UNSAFE_className={styles.label_wrapper_delete} justifyContent={'start'} alignItems={'start'}>
                {!isMobile && (
                  <Flex UNSAFE_className={styles.lable_delete} alignSelf={'stretch'} height={'9px'} alignItems={'center'} justifyContent={'center'}>
                    <div onClick={clearSignature}>Delete</div>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <View UNSAFE_className={isMobile ? styles.rectangle_parent_mobile : styles.rectangle_parent}>
          <Flex UNSAFE_className={styles.signature_control}>
            <SignatureCanvas
              id={signatureKey}
              penColor={statusColor(signatureColor?.toString() ?? '#000')}
              canvasProps={{
                className: isMobile ? styles.signature_pad_mobile : styles.signature_pad,
              }}
              dotSize={signatureSize ?? 3}
              minWidth={1}
              maxWidth={signatureSize ?? 3}
              ref={sigCanvas}
              onEnd={onChange}
            />
          </Flex>
          <div className={styles.line_div_container}>
            <div className={styles.line_div}></div>
          </div>
          <Flex UNSAFE_className={isMobile ? styles.label_fullname_text_mobile : styles.label_fullname_text} direction={'row'} justifyContent={'center'} alignContent={'center'}>
            {fullName}
          </Flex>
        </View>
        <Flex width={'100%'} justifyContent={'end'} alignContent={'end'} marginBottom={isMobile ? 20 : 0} marginTop={isMobile ? 25 : 0}>
          {fullName && signBase64 && (
            <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={handleConfirm}>
              Confirm and open
            </Link>
          )}
        </Flex>
      </Flex>
    );
  }
}

export const SignComponent = LayoutComponent(Signature);
