import { FPADataTypes } from "../../infra/protected/FPA/FPAData";
import { IFeatureService } from "../../services/soap/features/FeatureService";
import { UpdateRegisterRequest } from "../../services/soap/features/requests/UpdateRegisterRequest";
import { IProjectService } from "../../services/soap/project/ProjectService";
import { GetContextPathRequest } from "../../services/soap/project/requests/GetContextPathRequest";
import { Col, FilterActivity, FilterCol, FilterFolder, FilterProject, ListFPARequest, Order, Parent } from "../../services/soap/project/requests/ListFPARequest";
import { GetContextPathResponse } from "../../services/soap/project/responses/GetContextPathResponse";
import { ListFPAResponse } from "../../services/soap/project/responses/ListFPAResponse";
import { BaseThunkPayload } from "../lib/BaseThunkPayload";

export interface LoadTreeDataThunkPayload extends BaseThunkPayload{
    api: IProjectService;
    itemType: FPADataTypes;
    itemId: number;
    offset: number;
    limit: number;
};

export interface DoSearchThunkPayload extends BaseThunkPayload{
    api: IProjectService;
    itemType?: FPADataTypes;
    itemId?: number;
    searchText?: string;
    offset: number;
    limit: number;
};

export interface GetContextPathPayload extends BaseThunkPayload{
    api: IProjectService;
    contextId: number;
    isForSearch?: boolean;
}

export interface UpdateFilterPayload extends BaseThunkPayload{
  api:IFeatureService;
  id:number;
  moduleId:string;
  key:string;
  level:number;
  value:string;
  assignTo:number;
}

const generateFilters = (filter:any ):{
  folderFilter: FilterFolder,
  projectFilter: FilterProject,
  activityFilter: FilterActivity
} => {
  var folder_filter_options = [];
  var project_filter_options = [];
  var activity_filter_options = [];
  
  var folderFilter:FilterFolder = new FilterFolder();
  var projectFilter:FilterProject = new FilterProject();
  var activityFilter:FilterActivity = new FilterActivity();

  const _hasFilter = (filter:any[]):boolean => {
    return filter.filter((item:string) => item !== '').length > 0 ? true : false;
  };

  const _getFilter = (filter:any[]):FilterCol => {
    return new FilterCol('state', 'in', filter.filter((item:string) => item !== '').join(','));
  }
  if(filter && filter.FinderSettings && filter.FinderSettings.finderViewSettings) {
    folder_filter_options = filter.FinderSettings.finderViewSettings.folderFilter.split(',').map((item:string, idx:number) => item ==='true' ? `${idx+1}`:'');
    project_filter_options = filter.FinderSettings.finderViewSettings.projectFilter.split(',').map((item:string, idx:number) => item ==='true' ? `${idx+1}`:'');
    activity_filter_options = filter.FinderSettings.finderViewSettings.activityFilter.split(',').map((item:string, idx:number) => item ==='true' ? `${idx+1}`:'');

    if(filter.FinderSettings.finderViewSettings.projectTypeFilter){
      projectFilter.COL.push(new FilterCol('projectType', 'equals', filter.FinderSettings.finderViewSettings.projectTypeFilter));
    }

    if(filter.FinderSettings.finderViewSettings.responsibleUserFilter){
      folderFilter.COL.push(new FilterCol('responsibleUser', 'equals', filter.FinderSettings.finderViewSettings.responsibleUserFilter));
      projectFilter.COL.push(new FilterCol('responsibleUser', 'equals', filter.FinderSettings.finderViewSettings.responsibleUserFilter));
    }
  }

  if(folder_filter_options.length > 0){
    if(_hasFilter(folder_filter_options))
      folderFilter.COL.push(_getFilter(folder_filter_options));
  }

  if(project_filter_options.length > 0){
    if(_hasFilter(project_filter_options))
      projectFilter.COL.push(_getFilter(project_filter_options));
  }

  if(activity_filter_options.length > 0){
    if(_hasFilter(activity_filter_options))
      activityFilter.COL.push(_getFilter(activity_filter_options));
  }

  return {
    folderFilter,
    projectFilter,
    activityFilter
  };
}

const requestTreeData = async (
  api:IProjectService,
  sessionId:string,
  server:string,
  filter:any,
  itemType?:FPADataTypes,
  itemId?:number,
  searchText?:string,
  offset?:number,
  limit?:number,
  countOnly:boolean = false
  ):Promise<ListFPAResponse> => {
    const { folderFilter, projectFilter, activityFilter } = generateFilters(filter);
    var parentContext = undefined;

    if(itemId !== undefined && itemType !== undefined)
    switch(itemType){
      case FPADataTypes.FOLDER_TYPE:
        folderFilter.COL.push(new FilterCol('folderType', 'equals', itemId.toString()));
        break;
      case FPADataTypes.FOLDER:
        parentContext = new Parent(itemId, 'FOLDER');
        break;
      case FPADataTypes.PROJECT:
        parentContext = new Parent(itemId, 'PROJECT');
        break;
      case FPADataTypes.ACTIVITY:
        parentContext = new Parent(itemId, 'ACTIVITY');
        break;
    }

    const request = new ListFPARequest(server, sessionId, 
      searchText ? searchText + "*" : undefined, 
      false, 
      limit, 
      offset,
      undefined,
      countOnly,
      undefined,
      parentContext,
      folderFilter,
      projectFilter, 
      activityFilter,
      new Order([
        new Col('itemTypeName'), 
        new Col('name'), 
        new Col('created', true)
      ])
    );
    const response = await api.listFPA(request);
    return response;
}

export const loadTreeDataThunk = async (payload:LoadTreeDataThunkPayload, thunkAPI: any ):Promise<{total_count:number, total_rows:ListFPAResponse}> => {
    const { 
      api, 
      itemType, 
      itemId, 
      sessionId, 
      server,
      offset,
      limit
    } = payload;
    const { getState } = thunkAPI;
    const { childrenCache } = getState().finder;

    var total_count = 0;

    if(offset === 0 && !childrenCache[itemId]){
      var total_rows_response = await requestTreeData(
        api, 
        sessionId, 
        server, getState().finder.searchFilter, 
        itemType, 
        itemId, 
        undefined, 
        undefined, 
        undefined, 
        true);
  
      total_count = total_rows_response.count;
    }

    if(childrenCache[itemId] && childrenCache[itemId][offset]){
      return { 
        total_count, 
        total_rows:{ ITEMS: [...childrenCache[itemId][offset]] } as ListFPAResponse
      };
    } 

    var total_rows = await requestTreeData(
      api, 
      sessionId, 
      server, 
      getState().finder.searchFilter, 
      itemType, 
      itemId, 
      undefined, 
      offset, 
      limit, 
      false);
    return { total_rows, total_count }
}

export const doSearchThunk = async (payload:DoSearchThunkPayload, thunkAPI: any ):Promise<{total_count:number, total_rows:ListFPAResponse}> => {
  const { 
    api, 
    itemType, 
    itemId, 
    sessionId, 
    server,
    searchText,
    offset,
    limit
  } = payload;
  const { getState } = thunkAPI;
  var total_count = getState().finder.search_result_total;

  if(total_count === 0){
    var total_rows_response = await requestTreeData(
      api, 
      sessionId, 
      server, getState().finder.searchFilter, 
      itemType, 
      itemId, 
      searchText, 
      undefined, 
      undefined, 
      true);

    total_count = total_rows_response.count;
  }
    
  var total_rows = await requestTreeData(
    api, 
    sessionId, 
    server, 
    getState().finder.searchFilter, 
    itemType, 
    itemId, 
    searchText, 
    offset, 
    limit, 
    false);

  return { total_count, total_rows};
}

export const getContextPathThunk = async (payload:GetContextPathPayload, thunkAPI: any ):Promise<GetContextPathResponse> => {
  const { 
    api, 
    contextId, 
    sessionId, 
    server
  } = payload;
  return await api.getContextPath(new GetContextPathRequest(server, sessionId, contextId));
}

export const updateFiltersThunk = async (payload:UpdateFilterPayload, thunkAPI: any ):Promise<void> => {
  const { 
    api, 
    server,
    sessionId,
    id, 
    moduleId, 
    key, 
    level, 
    value, 
    assignTo
  } = payload;
  await api.updateRegister(new UpdateRegisterRequest(
    server, 
    sessionId, 
    id,
    undefined,
    moduleId,
    undefined,
    key,
    level,
    value,
    assignTo
  ));
}