import * as React from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { LayoutDefinition } from '../../../../infra/protected/Layout/LayoutDefinitions';
import { Flex} from '@adobe/react-spectrum';
import { ComponentFactory } from '../ComponentFactory';

export interface IHorizontalLayoutProps {
  children?: LayoutDefinition[];
}

function HorizontalLayout ({ children }: IHorizontalLayoutProps) {
  if(!children) return (<>No children</>);
  return (
      <Flex direction={{ base: 'column', M: 'row'}} gap="size-100">
          {children.map((child, index) => (<ComponentFactory key={index} definition={child} />))}
      </Flex>
  );
}

export const HorizontalLayoutComponent = LayoutComponent(HorizontalLayout);