import ConstantUtils from "../../../../utils/ConstantUtils";

export interface TimeSheetItem {
    userName?:              string;
    contactName?:           string;
    typeOfWorkColor?:       string;
    quantity?:              string;
    dimension3Name?:        string;
    dimension3?:            string;
    dimension2Name?:        string;
    dimension2?:            string;
    dimension1Name?:        string;
    dimension1?:            string;
    folderItem?:            string;
    projectItem?:           string;
    resultCost?:            string;
    hourlyCost?:            string;
    switchTotal?:           string;
    created?:               Date;
    createdBy?:             string;
    modified?:              Date;
    approved?:              string;
    approvedBy?:            Date;
    dateTo?:                Date;
    vat?:                   string;
    qty?:                   string;
    usedQuantity?:          string;
    resultPrice?:           string;
    isExpense?:             string;
    taskId?:                string;
    customId?:              string;
    nodeId?:                string;
    currency?:              string;
    currencyCost?:          string;
    rate?:                  string;
    status?:                string;
    comments?:              string;
    description?:           string;
    title?:                 string;
    typeOfWorkId?:          string;
    schedId?:               string;
    minutes?:               string;
    entryDate?:             Date;
    activityId?:            string;
    projectId?:             string;
    folderId?:              string;
    chargeable?:            string;
    entererId?:             string;
    resoId?:                string;
    userId?:                string;
    ident?:                 string;
    refId?:                 string;
    activityName?:          string;
    projectName?:           string;
    folderName?:            string;
    masterProjectId?:       string;
    masterFolderId?:        string;
    masterProjectName?:     string;
    masterFolderName?:      string;
    customerName?:          string;
    providerName?:          string;
    typeOfWorkName?:        string;
    entererName?:           string;
    entryTypeName?:         string;
    contractType?:          string;
    plannedTaskCurrent?:    string;
    invoiceId?:             string;
    invoiceNumber?:         string;
    itmVat?:                string;
    itmCurrency?:           string;
    itmVatValue?:           string;
    itmAmount?:             string;
    itmTotalAmount?:        string;
    itmTotalAmountWithVat?: string;
    wageType?:              string;
    wageTypeName?:          string;
    fixWageType?:           string;
    fixWageTypeName?:       string;
    userDimension1Name?:    string;
    userDimension1?:        string;
    userDimension2Name?:    string;
    userDimension2?:        string;
    userDimension3Name?:    string;
    userDimension3?:        string;
    contextDimension1Name?: string;
    contextDimension1?:     string;
    contextDimension2Name?: string;
    contextDimension2?:     string;
    contextDimension3Name?: string;
    contextDimension3?:     string;
}

export function toTimeSheetItemXML(item: TimeSheetItem): string{
    var parameters: string = '';

    if(item.userName) parameters += '<userName>' + item.userName + '</userName>';
    if(item.contactName) parameters += '<contactName>' + item.contactName + '</contactName>';
    if(item.typeOfWorkColor) parameters += '<typeOfWorkColor>' + item.typeOfWorkColor + '</typeOfWorkColor>';
    if(item.quantity) parameters += '<quantity>' + item.quantity + '</quantity>';
    if(item.dimension3Name) parameters += '<dimension3Name>' + item.dimension3Name + '</dimension3Name>';
    if(item.dimension3) parameters += '<dimension3>' + item.dimension3 + '</dimension3>';
    if(item.dimension2Name) parameters += '<dimension2Name>' + item.dimension2Name + '</dimension2Name>';
    if(item.dimension2) parameters += '<dimension2>' + item.dimension2 + '</dimension2>';
    if(item.dimension1Name) parameters += '<dimension1Name>' + item.dimension1Name + '</dimension1Name>';
    if(item.dimension1) parameters += '<dimension1>' + item.dimension1 + '</dimension1>';
    if(item.folderItem) parameters += '<folderItem>' + item.folderItem + '</folderItem>';
    if(item.projectItem) parameters += '<projectItem>' + item.projectItem + '</projectItem>';
    if(item.resultCost) parameters += '<resultCost>' + item.resultCost + '</resultCost>';
    if(item.hourlyCost) parameters += '<hourlyCost>' + item.hourlyCost + '</hourlyCost>';
    if(item.switchTotal) parameters += '<switchTotal>' + item.switchTotal + '</switchTotal>';
    if(item.created) parameters += '<created>' + ConstantUtils.xmlFormatDate(item.created) + '</created>';
    if(item.createdBy) parameters += '<createdBy>' + item.createdBy + '</createdBy>';
    if(item.modified) parameters += '<modified>' + ConstantUtils.xmlFormatDate(item.modified) + '</modified>';
    if(item.approved) parameters += '<approved>' + item.approved + '</approved>';
    if(item.approvedBy) parameters += '<approvedBy>' + ConstantUtils.xmlFormatDate(item.approvedBy) + '</approvedBy>';
    if(item.dateTo) parameters += '<dateTo>' + ConstantUtils.xmlFormatDate(item.dateTo) + '</dateTo>';
    if(item.vat) parameters += '<vat>' + item.vat + '</vat>';
    if(item.qty) parameters += '<qty>' + item.qty + '</qty>';
    if(item.usedQuantity) parameters += '<usedQuantity>' + item.usedQuantity + '</usedQuantity>';
    if(item.resultPrice) parameters += '<resultPrice>' + item.resultPrice + '</resultPrice>';
    if(item.isExpense) parameters += '<isExpense>' + item.isExpense + '</isExpense>';
    if(item.taskId) parameters += '<taskId>' + item.taskId + '</taskId>';
    if(item.customId) parameters += '<customId>' + item.customId + '</customId>';
    if(item.nodeId) parameters += '<nodeId>' + item.nodeId + '</nodeId>';
    if(item.currency) parameters += '<currency>' + item.currency + '</currency>';
    if(item.currencyCost) parameters += '<currencyCost>' + item.currencyCost + '</currencyCost>';
    if(item.rate) parameters += '<rate>' + item.rate + '</rate>';
    if(item.status) parameters += '<status>' + item.status + '</status>';
    if(item.comments) parameters += '<comments>' + item.comments + '</comments>';
    if(item.description) parameters += '<description>' + item.description + '</description>';
    if(item.title) parameters += '<title>' + item.title + '</title>';
    if(item.typeOfWorkId) parameters += '<typeOfWorkId>' + item.typeOfWorkId + '</typeOfWorkId>';
    if(item.schedId) parameters += '<schedId>' + item.schedId + '</schedId>';
    if(item.minutes) parameters += '<minutes>' + item.minutes + '</minutes>';
    if(item.entryDate) parameters += '<entryDate>' + ConstantUtils.xmlFormatDate(item.entryDate) + '</entryDate>';
    if(item.activityId) parameters += '<activityId>' + item.activityId + '</activityId>';
    if(item.projectId) parameters += '<projectId>' + item.projectId + '</projectId>';
    if(item.folderId) parameters += '<folderId>' + item.folderId + '</folderId>';
    if(item.chargeable) parameters += '<chargeable>' + item.chargeable + '</chargeable>';
    if(item.entererId) parameters += '<entererId>' + item.entererId + '</entererId>';
    if(item.resoId) parameters += '<resoId>' + item.resoId + '</resoId>';
    if(item.userId) parameters += '<userId>' + item.userId + '</userId>';
    if(item.ident) parameters += '<ident>' + item.ident + '</ident>';
    if(item.refId) parameters += '<refId>' + item.refId + '</refId>';
    if(item.activityName) parameters += '<activityName>' + item.activityName + '</activityName>';
    if(item.projectName) parameters += '<projectName>' + item.projectName + '</projectName>';
    if(item.folderName) parameters += '<folderName>' + item.folderName + '</folderName>';
    if(item.masterProjectId) parameters += '<masterProjectId>' + item.masterProjectId + '</masterProjectId>';
    if(item.masterFolderId) parameters += '<masterFolderId>' + item.masterFolderId + '</masterFolderId>';
    if(item.masterProjectName) parameters += '<masterProjectName>' + item.masterProjectName + '</masterProjectName>';
    if(item.masterFolderName) parameters += '<masterFolderName>' + item.masterFolderName + '</masterFolderName>';
    if(item.customerName) parameters += '<customerName>' + item.customerName + '</customerName>';
    if(item.providerName) parameters += '<providerName>' + item.providerName + '</providerName>';
    if(item.typeOfWorkName) parameters += '<typeOfWorkName>' + item.typeOfWorkName + '</typeOfWorkName>';
    if(item.entererName) parameters += '<entererName>' + item.entererName + '</entererName>';
    if(item.entryTypeName) parameters += '<entryTypeName>' + item.entryTypeName + '</entryTypeName>';
    if(item.contractType) parameters += '<contractType>' + item.contractType + '</contractType>';
    if(item.plannedTaskCurrent) parameters += '<plannedTaskCurrent>' + item.plannedTaskCurrent + '</plannedTaskCurrent>';
    if(item.invoiceId) parameters += '<invoiceId>' + item.invoiceId + '</invoiceId>';
    if(item.invoiceNumber) parameters += '<invoiceNumber>' + item.invoiceNumber + '</invoiceNumber>';
    if(item.itmVat) parameters += '<itmVat>' + item.itmVat + '</itmVat>';
    if(item.itmCurrency) parameters += '<itmCurrency>' + item.itmCurrency + '</itmCurrency>';
    if(item.itmVatValue) parameters += '<itmVatValue>' + item.itmVatValue + '</itmVatValue>';
    if(item.itmAmount) parameters += '<itmAmount>' + item.itmAmount + '</itmAmount>';
    if(item.itmTotalAmount) parameters += '<itmTotalAmount>' + item.itmTotalAmount + '</itmTotalAmount>';
    if(item.itmTotalAmountWithVat) parameters += '<itmTotalAmountWithVat>' + item.itmTotalAmountWithVat + '</itmTotalAmountWithVat>';
    if(item.wageType) parameters += '<wageType>' + item.wageType + '</wageType>';
    if(item.wageTypeName) parameters += '<wageTypeName>' + item.wageTypeName + '</wageTypeName>';
    if(item.fixWageType) parameters += '<fixWageType>' + item.fixWageType + '</fixWageType>';
    if(item.fixWageTypeName) parameters += '<fixWageTypeName>' + item.fixWageTypeName + '</fixWageTypeName>';
    if(item.userDimension1Name) parameters += '<userDimension1Name>' + item.userDimension1Name + '</userDimension1Name>';
    if(item.userDimension1) parameters += '<userDimension1>' + item.userDimension1 + '</userDimension1>';
    if(item.userDimension2Name) parameters += '<userDimension2Name>' + item.userDimension2Name + '</userDimension2Name>';
    if(item.userDimension2) parameters += '<userDimension2>' + item.userDimension2 + '</userDimension2>';
    if(item.userDimension3Name) parameters += '<userDimension3Name>' + item.userDimension3Name + '</userDimension3Name>';
    if(item.userDimension3) parameters += '<userDimension3>' + item.userDimension3 + '</userDimension3>';

    return parameters;
}