export interface Folder {
    parentPath?:         string;
    type?:               string;
    id?:                 string;
    aclh?:               string;
    comment?:            string;
    folderType?:         string;
    folderLinkType?:     string;
    folderTypeName?:     string;
    typeFormId?:         string;
    formId?:             string;
    formName?:           string;
    referenceId?:        string;
    name?:               string;
    name1?:              string;
    name2?:              string;
    name3?:              string;
    formValues?:         string;
    messageGroup?:       string;
    messageDefault?:     string;
    guiAction?:          string;
    contactType?:        string;
    customState?:        string;
    customStateName?:    string;
    customStateColor?:   string;
    organization?:       string;
    created?:            Date;
    createdByName?:      string;
    firstContactDate?:   Date;
    lastContactDate?:    Date;
    lastDocDate?:        Date;
    modified?:           Date;
    modifiedByName?:     string;
    defaultTemplate?:    string;
    treeHandle?:         string;
    itemId?:             string;
    itemName?:           string;
    pricing?:            string;
    pricingName?:        string;
    parent?:             string;
    childCount?:         string;
    parentRoot?:         string;
    userTemplate?:       string;
    dimension1?:         string;
    dimension1Name?:     string;
    dimension2?:         string;
    dimension2Name?:     string;
    dimension3?:         string;
    dimension3Name?:     string;
    avalDimension1?:     string;
    avalDimension1Name?: string;
    avalDimension2?:     string;
    avalDimension2Name?: string;
    avalDimension3?:     string;
    avalDimension3Name?: string;
    messageDefaultPath?: string;
    template?:           string;
    contact?:            string;
    item?:               string;
    state?:              string;
    folderId?:           string;
    keepModified?:       string;
    notificationId?:     string;
    itemPricing?:        string;
    itemPricingName?:    string;
    primaryPicture?:     string;
    contextParent?:      string;
    contextParentName?:  string;
    PATH?:               Path;
    PROJECTS?:           Projects;
    CONTACT?:            Contact;
    LOCATION?:           Location;
    EXTERNAL_CONTACTS?:  ExternalContacts;
    PRIMARYCONTACT?:     Contact;
}

export function toFolderXml(folder?:Folder):string{
    var xml = '';

    if(folder){
        xml += '<FOLDER>';
        if(folder.parentPath) xml += `<parentPath>${folder.parentPath}</parentPath>`;
        if(folder.type) xml += `<type>${folder.type}</type>`;
        if(folder.id) xml += `<id>${folder.id}</id>`;
        if(folder.aclh) xml += `<aclh>${folder.aclh}</aclh>`;
        if(folder.comment) xml += `<comment>${folder.comment}</comment>`;
        if(folder.folderType) xml += `<folderType>${folder.folderType}</folderType>`;
        if(folder.folderLinkType) xml += `<folderLinkType>${folder.folderLinkType}</folderLinkType>`;
        if(folder.folderTypeName) xml += `<folderTypeName>${folder.folderTypeName}</folderTypeName>`;
        if(folder.typeFormId) xml += `<typeFormId>${folder.typeFormId}</typeFormId>`;
        if(folder.formId) xml += `<formId>${folder.formId}</formId>`;
        if(folder.formName) xml += `<formName>${folder.formName}</formName>`;
        if(folder.referenceId) xml += `<referenceId>${folder.referenceId}</referenceId>`;
        if(folder.name) xml += `<name>${folder.name}</name>`;
        if(folder.name1) xml += `<name1>${folder.name1}</name1>`;
        if(folder.name2) xml += `<name2>${folder.name2}</name2>`;
        if(folder.name3) xml += `<name3>${folder.name3}</name3>`;
        if(folder.formValues) xml += `<formValues>${folder.formValues}</formValues>`;
        if(folder.messageGroup) xml += `<messageGroup>${folder.messageGroup}</messageGroup>`;
        if(folder.messageDefault) xml += `<messageDefault>${folder.messageDefault}</messageDefault>`;
        if(folder.guiAction) xml += `<guiAction>${folder.guiAction}</guiAction>`;
        if(folder.contactType) xml += `<contactType>${folder.contactType}</contactType>`;
        if(folder.customState) xml += `<customState>${folder.customState}</customState>`;
        if(folder.customStateName) xml += `<customStateName>${folder.customStateName}</customStateName>`;
        if(folder.customStateColor) xml += `<customStateColor>${folder.customStateColor}</customStateColor>`;
        if(folder.organization) xml += `<organization>${folder.organization}</organization>`;
        if(folder.created) xml += `<created>${new Date(folder.created).toISOString()}</created>`;
        if(folder.createdByName) xml += `<createdByName>${folder.createdByName}</createdByName>`;
        if(folder.firstContactDate) xml += `<firstContactDate>${new Date(folder.firstContactDate).toISOString()}</firstContactDate>`;
        if(folder.lastContactDate) xml += `<lastContactDate>${new Date(folder.lastContactDate).toISOString()}</lastContactDate>`;
        if(folder.lastDocDate) xml += `<lastDocDate>${new Date(folder.lastDocDate).toISOString()}</lastDocDate>`;
        if(folder.modified) xml += `<modified>${new Date(folder.modified).toISOString()}</modified>`;
        if(folder.modifiedByName) xml += `<modifiedByName>${folder.modifiedByName}</modifiedByName>`;
        if(folder.defaultTemplate) xml += `<defaultTemplate>${folder.defaultTemplate}</defaultTemplate>`;
        if(folder.treeHandle) xml += `<treeHandle>${folder.treeHandle}</treeHandle>`;
        if(folder.itemId) xml += `<itemId>${folder.itemId}</itemId>`;
        if(folder.itemName) xml += `<itemName>${folder.itemName}</itemName>`;
        if(folder.pricing) xml += `<pricing>${folder.pricing}</pricing>`;
        if(folder.pricingName) xml += `<pricingName>${folder.pricingName}</pricingName>`;
        if(folder.parent) xml += `<parent>${folder.parent}</parent>`;
        if(folder.childCount) xml += `<childCount>${folder.childCount}</childCount>`;
        if(folder.parentRoot) xml += `<parentRoot>${folder.parentRoot}</parentRoot>`;
        if(folder.userTemplate) xml += `<userTemplate>${folder.userTemplate}</userTemplate>`;
        if(folder.dimension1) xml += `<dimension1>${folder.dimension1}</dimension1>`;
        if(folder.dimension1Name) xml += `<dimension1Name>${folder.dimension1Name}</dimension1Name>`;
        if(folder.dimension2) xml += `<dimension2>${folder.dimension2}</dimension2>`;
        if(folder.dimension2Name) xml += `<dimension2Name>${folder.dimension2Name}</dimension2Name>`;
        if(folder.dimension3) xml += `<dimension3>${folder.dimension3}</dimension3>`;
        if(folder.dimension3Name) xml += `<dimension3Name>${folder.dimension3Name}</dimension3Name>`;
        if(folder.avalDimension1) xml += `<avalDimension1>${folder.avalDimension1}</avalDimension1>`;
        if(folder.avalDimension1Name) xml += `<avalDimension1Name>${folder.avalDimension1Name}</avalDimension1Name>`;
        if(folder.avalDimension2) xml += `<avalDimension2>${folder.avalDimension2}</avalDimension2>`;
        if(folder.avalDimension2Name) xml += `<avalDimension2Name>${folder.avalDimension2Name}</avalDimension2Name>`;
        if(folder.avalDimension3) xml += `<avalDimension3>${folder.avalDimension3}</avalDimension3>`;
        if(folder.avalDimension3Name) xml += `<avalDimension3Name>${folder.avalDimension3Name}</avalDimension3Name>`;
        if(folder.messageDefaultPath) xml += `<messageDefaultPath>${folder.messageDefaultPath}</messageDefaultPath>`;
        if(folder.template) xml += `<template>${folder.template}</template>`;
        if(folder.contact) xml += `<contact>${folder.contact}</contact>`;
        if(folder.item) xml += `<item>${folder.item}</item>`;
        if(folder.state) xml += `<state>${folder.state}</state>`;
        if(folder.folderId) xml += `<folderId>${folder.folderId}</folderId>`;
        if(folder.keepModified) xml += `<keepModified>${folder.keepModified}</keepModified>`;
        if(folder.notificationId) xml += `<notificationId>${folder.notificationId}</notificationId>`;
        if(folder.itemPricing) xml += `<itemPricing>${folder.itemPricing}</itemPricing>`;
        if(folder.itemPricingName) xml += `<itemPricingName>${folder.itemPricingName}</itemPricingName>`;
        if(folder.primaryPicture) xml += `<primaryPicture>${folder.primaryPicture}</primaryPicture>`;
        if(folder.contextParent) xml += `<contextParent>${folder.contextParent}</contextParent>`;
        if(folder.contextParentName) xml += `<contextParentName>${folder.contextParentName}</contextParentName>`;
        if(folder.PATH) xml += toPathXml(folder.PATH);
        if(folder.PROJECTS) xml += toProjectsXml(folder.PROJECTS);
        if(folder.CONTACT) xml += toContactXml(folder.CONTACT);
        if(folder.LOCATION) xml += toLocationXml(folder.LOCATION);
        if(folder.EXTERNAL_CONTACTS) xml += toExternalContactsXml(folder.EXTERNAL_CONTACTS);
        if(folder.PRIMARYCONTACT) xml += toContactXml(folder.PRIMARYCONTACT);
        xml += '</FOLDER>';
    }
    
    return xml;
}
// export interface Contact {
//     PERSON:  Company[];
//     COMPANY?: Company;
// }

export interface Company {
    id?:                   string;
    mainContact?:          string;
    treeHandle?:           string;
    aclh?:                 string;
    account?:              string;
    accresponsiblePerson?: string;
    accresponsibleUserId?: string;
    bankCode?:             string;
    bankName?:             string;
    category?:             string;
    sysUser?:              string;
    sysUserName?:          string;
    description?:          string;
    keyId?:                string;
    formId?:               string;
    formValues?:           string;
    adsAllowed?:           string;
    keepContact?:          string;
    validFrom?:            string;
    validTo?:              string;
    created?:              string;
    modified?:             string;
    createdBy?:            string;
    modifiedBy?:           string;
    createdByName?:        string;
    modifiedByName?:       string;
    projectId?:            string;
    folderId?:             string;
    projectName?:          string;
    folderName?:           string;
    oldId?:                string;
    hash?:                 string;
    organizationId?:       string;
    position?:             string;
    positionInfo?:         string;
    positionName?:         string;
    reference?:            string;
    swiftCode?:            string;
    sourceDb?:             string;
    trackingCode?:         string;
    name1?:                string;
    name2?:                string;
    name3?:                string;
    city?:                 string;
    country?:              string;
    email?:                string;
    fax?:                  string;
    icq?:                  string;
    mobile?:               string;
    notSimilarName?:       string;
    other?:                string;
    linkedin?:             string;
    twitter?:              string;
    facebook?:             string;
    phone?:                string;
    pager?:                string;
    region?:               string;
    street?:               string;
    web?:                  string;
    zip?:                  string;
    dueDays?:              string;
    priority?:             string;
    bankIBAN?:             string;
    bankNoteForeign?:      string;
    consentStatus?:        string;
    consentNote?:          string;
    anonymized?:           string;
    employer?:             string;
    adsAllowedFrom?:       string;
    massmailEnabledFrom?:  string;
    massmailEnabledTo?:    string;
    massmailEnabled?:      string;
    ADDRESSES?:            Addresses;
    CONTACTS?:             Contacts;
    BANKS?:                Banks;
    DGROUPS?:              Dgroups;
    EXTERNAL_IDS?:         ExternalIDS;
    company?:             string;
    companyName?:         string;
    companyOldId?:        string;
    configTreeHandle?:    string;
    department?:          string;
    departmentInfo?:      string;
    departmentName?:      string;
    isPrimary?:           string;
    COMPANYINFO?:          Companyinfo;
    INDUSTRIES?:          Industries;
    RELATIONS?:            Relations;
    FOLDERS?:              Folders;
    DOCUMENT?:             Document[];
    PERSONINFO?:          Personinfo;
    EMPLOYMENTS?:         Employments;
}

export function toCompanyXml(company?:Company):string {
    var xml = '';
    if(company){
        xml += '<COMPANY>';
        if(company.id) xml += `<id>${company.id}</id>`;
        if(company.mainContact) xml += `<mainContact>${company.mainContact}</mainContact>`;
        if(company.treeHandle) xml += `<treeHandle>${company.treeHandle}</treeHandle>`;
        if(company.aclh) xml += `<aclh>${company.aclh}</aclh>`;
        if(company.account) xml += `<account>${company.account}</account>`;
        if(company.accresponsiblePerson) xml += `<accresponsiblePerson>${company.accresponsiblePerson}</accresponsiblePerson>`;
        if(company.accresponsibleUserId) xml += `<accresponsibleUserId>${company.accresponsibleUserId}</accresponsibleUserId>`;
        if(company.bankCode) xml += `<bankCode>${company.bankCode}</bankCode>`;
        if(company.bankName) xml += `<bankName>${company.bankName}</bankName>`;
        if(company.category) xml += `<category>${company.category}</category>`;
        if(company.sysUser) xml += `<sysUser>${company.sysUser}</sysUser>`;
        if(company.sysUserName) xml += `<sysUserName>${company.sysUserName}</sysUserName>`;
        if(company.description) xml += `<description>${company.description}</description>`;
        if(company.keyId) xml += `<keyId>${company.keyId}</keyId>`;
        if(company.formId) xml += `<formId>${company.formId}</formId>`;
        if(company.formValues) xml += `<formValues>${company.formValues}</formValues>`;
        if(company.adsAllowed) xml += `<adsAllowed>${company.adsAllowed}</adsAllowed>`;
        if(company.keepContact) xml += `<keepContact>${company.keepContact}</keepContact>`;
        if(company.validFrom) xml += `<validFrom>${company.validFrom}</validFrom>`;
        if(company.validTo) xml += `<validTo>${company.validTo}</validTo>`;
        if(company.created) xml += `<created>${company.created}</created>`;
        if(company.modified) xml += `<modified>${company.modified}</modified>`;
        if(company.createdBy) xml += `<createdBy>${company.createdBy}</createdBy>`;
        if(company.modifiedBy) xml += `<modifiedBy>${company.modifiedBy}</modifiedBy>`;
        if(company.createdByName) xml += `<createdByName>${company.createdByName}</createdByName>`;
        if(company.modifiedByName) xml += `<modifiedByName>${company.modifiedByName}</modifiedByName>`;
        if(company.projectId) xml += `<projectId>${company.projectId}</projectId>`;
        if(company.folderId) xml += `<folderId>${company.folderId}</folderId>`;
        if(company.projectName) xml += `<projectName>${company.projectName}</projectName>`;
        if(company.folderName) xml += `<folderName>${company.folderName}</folderName>`;
        if(company.oldId) xml += `<oldId>${company.oldId}</oldId>`;
        if(company.hash) xml += `<hash>${company.hash}</hash>`;
        if(company.organizationId) xml += `<organizationId>${company.organizationId}</organizationId>`;
        if(company.position) xml += `<position>${company.position}</position>`;
        if(company.positionInfo) xml += `<positionInfo>${company.positionInfo}</positionInfo>`;
        if(company.positionName) xml += `<positionName>${company.positionName}</positionName>`;
        if(company.reference) xml += `<reference>${company.reference}</reference>`;
        if(company.swiftCode) xml += `<swiftCode>${company.swiftCode}</swiftCode>`;
        if(company.sourceDb) xml += `<sourceDb>${company.sourceDb}</sourceDb>`;
        if(company.trackingCode) xml += `<trackingCode>${company.trackingCode}</trackingCode>`;
        if(company.name1) xml += `<name1>${company.name1}</name1>`;
        if(company.name2) xml += `<name2>${company.name2}</name2>`;
        if(company.name3) xml += `<name3>${company.name3}</name3>`;
        if(company.city) xml += `<city>${company.city}</city>`;
        if(company.country) xml += `<country>${company.country}</country>`;
        if(company.email) xml += `<email>${company.email}</email>`;
        if(company.fax) xml += `<fax>${company.fax}</fax>`;
        if(company.icq) xml += `<icq>${company.icq}</icq>`;
        if(company.mobile) xml += `<mobile>${company.mobile}</mobile>`;
        if(company.notSimilarName) xml += `<notSimilarName>${company.notSimilarName}</notSimilarName>`;
        if(company.other) xml += `<other>${company.other}</other>`;
        if(company.linkedin) xml += `<linkedin>${company.linkedin}</linkedin>`;
        if(company.twitter) xml += `<twitter>${company.twitter}</twitter>`;
        if(company.facebook) xml += `<facebook>${company.facebook}</facebook>`;
        if(company.phone) xml += `<phone>${company.phone}</phone>`;
        if(company.pager) xml += `<pager>${company.pager}</pager>`;
        if(company.region) xml += `<region>${company.region}</region>`;
        if(company.street) xml += `<street>${company.street}</street>`;
        if(company.web) xml += `<web>${company.web}</web>`;
        if(company.zip) xml += `<zip>${company.zip}</zip>`;
        if(company.dueDays) xml += `<dueDays>${company.dueDays}</dueDays>`;
        if(company.priority) xml += `<priority>${company.priority}</priority>`;
        if(company.bankIBAN) xml += `<bankIBAN>${company.bankIBAN}</bankIBAN>`;
        if(company.bankNoteForeign) xml += `<bankNoteForeign>${company.bankNoteForeign}</bankNoteForeign>`;
        if(company.consentStatus) xml += `<consentStatus>${company.consentStatus}</consentStatus>`;
        if(company.consentNote) xml += `<consentNote>${company.consentNote}</consentNote>`;
        if(company.anonymized) xml += `<anonymized>${company.anonymized}</anonymized>`;
        if(company.employer) xml += `<employer>${company.employer}</employer>`;
        if(company.adsAllowedFrom) xml += `<adsAllowedFrom>${company.adsAllowedFrom}</adsAllowedFrom>`;
        if(company.massmailEnabledFrom) xml += `<massmailEnabledFrom>${company.massmailEnabledFrom}</massmailEnabledFrom>`;
        if(company.massmailEnabledTo) xml += `<massmailEnabledTo>${company.massmailEnabledTo}</massmailEnabledTo>`;
        if(company.massmailEnabled) xml += `<massmailEnabled>${company.massmailEnabled}</massmailEnabled>`;
        if(company.ADDRESSES) xml += toAddressesXml(company.ADDRESSES);
        if(company.CONTACTS) xml += toContactsXml(company.CONTACTS);
        if(company.BANKS) xml += toBanksXml(company.BANKS);
        if(company.DGROUPS) xml += toDgroupsXml(company.DGROUPS);
        if(company.EXTERNAL_IDS) xml += toExternalIdsXml(company.EXTERNAL_IDS);
        if(company.company) xml += `<company>${company.company}</company>`;
        if(company.companyName) xml += `<companyName>${company.companyName}</companyName>`;
        if(company.companyOldId) xml += `<companyOldId>${company.companyOldId}</companyOldId>`;
        if(company.configTreeHandle) xml += `<configTreeHandle>${company.configTreeHandle}</configTreeHandle>`;
        if(company.department) xml += `<department>${company.department}</department>`;
        if(company.departmentInfo) xml += `<departmentInfo>${company.departmentInfo}</departmentInfo>`;
        if(company.departmentName) xml += `<departmentName>${company.departmentName}</departmentName>`;
        if(company.isPrimary) xml += `<isPrimary>${company.isPrimary}</isPrimary>`;
        if(company.COMPANYINFO) xml += toCompanyinfoXml(company.COMPANYINFO);
        if(company.INDUSTRIES) xml += toIndustriesXml(company.INDUSTRIES);
        if(company.RELATIONS) xml += toRelationsXml(company.RELATIONS);
        if(company.FOLDERS) xml += toFoldersXml(company.FOLDERS);
        if(company.DOCUMENT) company.DOCUMENT.forEach(document => xml += toDocumentXml(document));
        if(company.PERSONINFO) xml += toPersoninfoXml(company.PERSONINFO);
        if(company.EMPLOYMENTS) xml += toEmploymentsXml(company.EMPLOYMENTS);
        xml += '</COMPANY>';
    }
    return xml;
}

export interface Addresses {
    ADDRESS: Address[];
}

export function toAddressesXml(addresses?: Addresses): string {
    var xml = '';
    if(addresses){
        xml += '<ADDRESSES>';
        if(addresses.ADDRESS) addresses.ADDRESS.forEach(address => xml += toAddressXml(address));
        xml += '</ADDRESSES>';
    }
    return xml;
}

export interface Address {
    city?:          string;
    cityName?:      string;
    companyAddr?:   string;
    country?:       string;
    countryName?:   string;
    deliveryAddr?:  string;
    empAddr?:       string;
    invoiceAddr?:   string;
    isPrimary?:     string;
    permanentAddr?: string;
    personAddr?:    string;
    region?:        string;
    regionName?:    string;
    street?:        string;
    temporaryAddr?: string;
    type?:          string;
    zip?:           string;
    zipName?:       string;
}

export function toAddressXml(address?: Address): string {
    var xml = '';
    if(address){
        xml += '<ADDRESS>';
        if(address.city) xml += `<city>${address.city}</city>`;
        if(address.cityName) xml += `<cityName>${address.cityName}</cityName>`;
        if(address.companyAddr) xml += `<companyAddr>${address.companyAddr}</companyAddr>`;
        if(address.country) xml += `<country>${address.country}</country>`;
        if(address.countryName) xml += `<countryName>${address.countryName}</countryName>`;
        if(address.deliveryAddr) xml += `<deliveryAddr>${address.deliveryAddr}</deliveryAddr>`;
        if(address.empAddr) xml += `<empAddr>${address.empAddr}</empAddr>`;
        if(address.invoiceAddr) xml += `<invoiceAddr>${address.invoiceAddr}</invoiceAddr>`;
        if(address.isPrimary) xml += `<isPrimary>${address.isPrimary}</isPrimary>`;
        if(address.permanentAddr) xml += `<permanentAddr>${address.permanentAddr}</permanentAddr>`;
        if(address.personAddr) xml += `<personAddr>${address.personAddr}</personAddr>`;
        if(address.region) xml += `<region>${address.region}</region>`;
        if(address.regionName) xml += `<regionName>${address.regionName}</regionName>`;
        if(address.street) xml += `<street>${address.street}</street>`;
        if(address.temporaryAddr) xml += `<temporaryAddr>${address.temporaryAddr}</temporaryAddr>`;
        if(address.type) xml += `<type>${address.type}</type>`;
        if(address.zip) xml += `<zip>${address.zip}</zip>`;
        if(address.zipName) xml += `<zipName>${address.zipName}</zipName>`;
        xml += '</ADDRESS>';
    }
    return xml;
}

export interface Banks {
    BANK: Bank[];
}

export function toBanksXml(banks?: Banks): string {
    var xml = '';
    if(banks){
        xml += '<BANKS>';
        if(banks.BANK) banks.BANK.forEach(bank => xml += toBankXml(bank));
        xml += '</BANKS>';
    }
    return xml;
}

export interface Bank {
    name?:            string;
    bankName?:        string;
    account?:         string;
    bankCode?:        string;
    swiftCode?:       string;
    variableSymbol?:  string;
    constantSymbol?:  string;
    specificSymbol?:  string;
    comment?:         string;
    isPrimary?:       string;
    bankIBAN?:        string;
    bankNoteForeign?: string;
}

export function toBankXml(bank?: Bank): string {
    var xml = '';
    if(bank){
        xml += '<BANK>';
        if(bank.name) xml += `<name>${bank.name}</name>`;
        if(bank.bankName) xml += `<bankName>${bank.bankName}</bankName>`;
        if(bank.account) xml += `<account>${bank.account}</account>`;
        if(bank.bankCode) xml += `<bankCode>${bank.bankCode}</bankCode>`;
        if(bank.swiftCode) xml += `<swiftCode>${bank.swiftCode}</swiftCode>`;
        if(bank.variableSymbol) xml += `<variableSymbol>${bank.variableSymbol}</variableSymbol>`;
        if(bank.constantSymbol) xml += `<constantSymbol>${bank.constantSymbol}</constantSymbol>`;
        if(bank.specificSymbol) xml += `<specificSymbol>${bank.specificSymbol}</specificSymbol>`;
        if(bank.comment) xml += `<comment>${bank.comment}</comment>`;
        if(bank.isPrimary) xml += `<isPrimary>${bank.isPrimary}</isPrimary>`;
        if(bank.bankIBAN) xml += `<bankIBAN>${bank.bankIBAN}</bankIBAN>`;
        if(bank.bankNoteForeign) xml += `<bankNoteForeign>${bank.bankNoteForeign}</bankNoteForeign>`;
        xml += '</BANK>';
    }
    return xml;
}

export interface Companyinfo {
    regNumber?:     string;
    VATNumber?:     string;
    legalForm?:     string;
    legalFormName?: string;
}

export function toCompanyinfoXml(companyinfo?: Companyinfo): string {
    var xml = '';
    if(companyinfo){
        xml += '<COMPANYINFO>';
        if(companyinfo.regNumber) xml += `<regNumber>${companyinfo.regNumber}</regNumber>`;
        if(companyinfo.VATNumber) xml += `<VATNumber>${companyinfo.VATNumber}</VATNumber>`;
        if(companyinfo.legalForm) xml += `<legalForm>${companyinfo.legalForm}</legalForm>`;
        if(companyinfo.legalFormName) xml += `<legalFormName>${companyinfo.legalFormName}</legalFormName>`;
        xml += '</COMPANYINFO>';
    }
    return xml;
}

export interface Contacts {
    CONTACT: Contact[];
}

export function toContactsXml(contacts?: Contacts): string {
    var xml = '';
    if(contacts){
        xml += '<CONTACTS>';
        if(contacts.CONTACT) contacts.CONTACT.forEach(contact => xml += toContactXml(contact));
        xml += '</CONTACTS>';
    }
    return xml;
}

export interface Contact {
    content?:     string;
    description?: string;
    isMain?:      string;
    isPrimary?:   string;
    type?:        string;
    typeName?:    string;
    typeType?:    string;
    category?:    string;
}

export function toContactXml(contact?: Contact): string {
    var xml = '';
    if(contact){
        xml += '<CONTACT>';
        if(contact.content) xml += `<content>${contact.content}</content>`;
        if(contact.description) xml += `<description>${contact.description}</description>`;
        if(contact.isMain) xml += `<isMain>${contact.isMain}</isMain>`;
        if(contact.isPrimary) xml += `<isPrimary>${contact.isPrimary}</isPrimary>`;
        if(contact.type) xml += `<type>${contact.type}</type>`;
        if(contact.typeName) xml += `<typeName>${contact.typeName}</typeName>`;
        if(contact.typeType) xml += `<typeType>${contact.typeType}</typeType>`;
        if(contact.category) xml += `<category>${contact.category}</category>`;
        xml += '</CONTACT>';
    }
    return xml;
}

export interface Dgroups {
    DGROUP: Dgroup[];
}

export function toDgroupsXml(dgroups?: Dgroups): string {
    var xml = '';
    if(dgroups){
        xml += '<DGROUPS>';
        if(dgroups.DGROUP) dgroups.DGROUP.forEach(dgroup => xml += toDgroupXml(dgroup));
        xml += '</DGROUPS>';
    }
    return xml;
}

export interface Dgroup {
    id?:       string;
    name?:     string;
    category?: string;
    comment?:  string;
}

export function toDgroupXml(dgroup?: Dgroup): string {
    var xml = '';
    if(dgroup){
        xml += '<DGROUP>';
        if(dgroup.id) xml += `<id>${dgroup.id}</id>`;
        if(dgroup.name) xml += `<name>${dgroup.name}</name>`;
        if(dgroup.category) xml += `<category>${dgroup.category}</category>`;
        if(dgroup.comment) xml += `<comment>${dgroup.comment}</comment>`;
        xml += '</DGROUP>';
    }
    return xml;
}

export interface Document {
    documentId?:       string;
    name?:             string;
    description?:      string;
    isPrimaryPicture?: string;
}

export function toDocumentXml(document?:Document):string{
    var xml = '';
    if(document){
        xml += '<DOCUMENT>';
        if(document.documentId) xml += `<documentId>${document.documentId}</documentId>`;
        if(document.name) xml += `<name>${document.name}</name>`;
        if(document.description) xml += `<description>${document.description}</description>`;
        if(document.isPrimaryPicture) xml += `<isPrimaryPicture>${document.isPrimaryPicture}</isPrimaryPicture>`;
        xml += '</DOCUMENT>';
    }
    return xml;
}

export interface Employments {
    COMPANY: EmploymentsCompany[];
}

export function toEmploymentsXml(employments?: Employments): string {
    var xml = '';
    if(employments){
        xml += '<EMPLOYMENTS>';
        if(employments.COMPANY) employments.COMPANY.forEach(company => xml += toEmploymentsCompanyXml(company));
        xml += '</EMPLOYMENTS>';
    }
    return xml;
}

export interface EmploymentsCompany {
    id?:             string;
    name?:           string;
    companyOldId?:   string;
    created?:        string;
    createdBy?:      string;
    department?:     string;
    departmentInfo?: string;
    departmentName?: string;
    isPrimary?:      string;
    modified?:       string;
    modifiedBy?:     string;
    position?:       string;
    positionInfo?:   string;
    positionName?:   string;
    reference?:      string;
    sourceDb?:       string;
    validFrom?:      string;
    validTo?:        string;
    type?:           string;
    originalName?:   string;
    custom1?:        string;
    custom2?:        string;
    custom3?:        string;
    FOLDERS?:        Folders;
}

export function toEmploymentsCompanyXml(employments_company?:EmploymentsCompany): string{
    var xml = '';

    if(employments_company){
        xml += '<COMPANY>';
        if(employments_company.id) xml += `<id>${employments_company.id}</id>`;
        if(employments_company.name) xml += `<name>${employments_company.name}</name>`;
        if(employments_company.companyOldId) xml += `<companyOldId>${employments_company.companyOldId}</companyOldId>`;
        if(employments_company.created) xml += `<created>${employments_company.created}</created>`;
        if(employments_company.createdBy) xml += `<createdBy>${employments_company.createdBy}</createdBy>`;
        if(employments_company.department) xml += `<department>${employments_company.department}</department>`;
        if(employments_company.departmentInfo) xml += `<departmentInfo>${employments_company.departmentInfo}</departmentInfo>`;
        if(employments_company.departmentName) xml += `<departmentName>${employments_company.departmentName}</departmentName>`;
        if(employments_company.isPrimary) xml += `<isPrimary>${employments_company.isPrimary}</isPrimary>`;
        if(employments_company.modified) xml += `<modified>${employments_company.modified}</modified>`;
        if(employments_company.modifiedBy) xml += `<modifiedBy>${employments_company.modifiedBy}</modifiedBy>`;
        if(employments_company.position) xml += `<position>${employments_company.position}</position>`;
        if(employments_company.positionInfo) xml += `<positionInfo>${employments_company.positionInfo}</positionInfo>`;
        if(employments_company.positionName) xml += `<positionName>${employments_company.positionName}</positionName>`;
        if(employments_company.reference) xml += `<reference>${employments_company.reference}</reference>`;
        if(employments_company.sourceDb) xml += `<sourceDb>${employments_company.sourceDb}</sourceDb>`;
        if(employments_company.validFrom) xml += `<validFrom>${employments_company.validFrom}</validFrom>`;
        if(employments_company.validTo) xml += `<validTo>${employments_company.validTo}</validTo>`;
        if(employments_company.type) xml += `<type>${employments_company.type}</type>`;
        if(employments_company.originalName) xml += `<originalName>${employments_company.originalName}</originalName>`;
        if(employments_company.custom1) xml += `<custom1>${employments_company.custom1}</custom1>`;
        if(employments_company.custom2) xml += `<custom2>${employments_company.custom2}</custom2>`;
        if(employments_company.custom3) xml += `<custom3>${employments_company.custom3}</custom3>`;
        if(employments_company.FOLDERS) xml += toFoldersXml(employments_company.FOLDERS);
        xml += '</COMPANY>';
    }

    return xml;
}
export interface Folders {
    FOLDER: Folder[];
}

export function toFoldersXml(folders?: Folders): string {
    var xml = '';
    if(folders){
        xml += '<FOLDERS>';
        if(folders.FOLDER) folders.FOLDER.forEach(folder => xml += toFolderXml(folder));
        xml += '</FOLDERS>';
    }
    return xml;
}

export interface ExternalIDS {
    EXTERNAL_ID: ExternalID[];
}

export function toExternalIdsXml(external_ids?: ExternalIDS):string{
    var xml = '';
    if(external_ids){
        xml += '<EXTERNAL_IDS>';
        if(external_ids.EXTERNAL_ID) external_ids.EXTERNAL_ID.forEach(external_id => xml += toExternalIDXml(external_id));
        xml += '</EXTERNAL_IDS>';
    }
    return xml;
}

export interface ExternalID {
    externalSystem?: string;
    externalId?:     string;
}

export function toExternalIDXml(external_id?:ExternalID):string{
    var xml = '';
    if(external_id){
        xml += '<EXTERNAL_ID>';
        if(external_id.externalSystem) xml += `<externalSystem>${external_id.externalSystem}</externalSystem>`;
        if(external_id.externalId) xml += `<externalId>${external_id.externalId}</externalId>`;
        xml += '</EXTERNAL_ID>';
    }
    return xml;
}

export interface Industries {
    INDUSTRY?: Industry;
}

export function toIndustriesXml(industries?: Industries): string {
    var xml = '';
    if(industries){
        xml += '<INDUSTRIES>';
        if(industries.INDUSTRY) xml += toIndustryXml(industries.INDUSTRY);
        xml += '</INDUSTRIES>';
    }
    return xml;
}

export interface Industry {
    description?:  string;
    industryName?: string;
    industry?:     string;
    isPrimary?:    string;
}

export function toIndustryXml(industry?: Industry): string {
    var xml = '';
    if(industry){
        xml += '<INDUSTRY>';
        if(industry.description) xml += `<description>${industry.description}</description>`;
        if(industry.industryName) xml += `<industryName>${industry.industryName}</industryName>`;
        if(industry.industry) xml += `<industry>${industry.industry}</industry>`;
        if(industry.isPrimary) xml += `<isPrimary>${industry.isPrimary}</isPrimary>`;
        xml += '</INDUSTRY>';
    }
    return xml;
}

export interface Personinfo {
    prefixTitle?:     string;
    postfixTitle?:    string;
    sex?:             string;
    sexName?:         string;
    maritalStatus?:   string;
    born?:            string;
    salutation?:      string;
    preferedLang?:    string;
    nationality?:     string;
    nationalityName?: string;
}

export function toPersoninfoXml(personinfo?: Personinfo): string {
    var xml = '';
    if(personinfo){
        xml += '<PERSONINFO>';
        if(personinfo.prefixTitle) xml += `<prefixTitle>${personinfo.prefixTitle}</prefixTitle>`;
        if(personinfo.postfixTitle) xml += `<postfixTitle>${personinfo.postfixTitle}</postfixTitle>`;
        if(personinfo.sex) xml += `<sex>${personinfo.sex}</sex>`;
        if(personinfo.sexName) xml += `<sexName>${personinfo.sexName}</sexName>`;
        if(personinfo.maritalStatus) xml += `<maritalStatus>${personinfo.maritalStatus}</maritalStatus>`;
        if(personinfo.born) xml += `<born>${personinfo.born}</born>`;
        if(personinfo.salutation) xml += `<salutation>${personinfo.salutation}</salutation>`;
        if(personinfo.preferedLang) xml += `<preferedLang>${personinfo.preferedLang}</preferedLang>`;
        if(personinfo.nationality) xml += `<nationality>${personinfo.nationality}</nationality>`;
        if(personinfo.nationalityName) xml += `<nationalityName>${personinfo.nationalityName}</nationalityName>`;
        xml += '</PERSONINFO>';
    }
    return xml;
}

export interface Relations {
    RELATION: Relation[];
}

export function toRelationsXml(relations?: Relations): string {
    var xml = '';
    if(relations){
        xml += '<RELATIONS>';
        if(relations.RELATION) relations.RELATION.forEach(relation => xml += toRelation(relation));
        xml += '</RELATIONS>';
    }
    return xml;
}

export interface Relation {
    id?:            string;
    name?:          string;
    company?:       string;
    contact?:       string;
    rSwap?:         string;
    typeName?:      string;
    type?:          string;
    contact1Id?:    string;
    contact2Id?:    string;
    contact1Name1?: string;
    contact1Name2?: string;
    contact1Name3?: string;
    contact2Name1?: string;
    contact2Name2?: string;
    contact2Name3?: string;
    contact1Phone?: string;
    contact2Phone?: string;
    contact1Email?: string;
    contact2Email?: string;
    description?:   string;
    relation?:      string;
    typeName1?:     string;
    typeName2?:     string;
    rtype?:         string;
    c1created?:     string;
    c1createdBy?:   string;
    c1modified?:    string;
    c1modifiedBy?:  string;
    c2created?:     string;
    c2createdBy?:   string;
    c2modified?:    string;
    c2modifiedBy?:  string;
    contact1OldId?: string;
    contact2OldId?: string;
    SUBJECT?:       Subject;
}

export function toRelation(relation:Relation):string{
    var xml = '';
    if(relation){
        xml += '<RELATION>';
        if(relation.id) xml += `<id>${relation.id}</id>`;
        if(relation.name) xml += `<name>${relation.name}</name>`;
        if(relation.company) xml += `<company>${relation.company}</company>`;
        if(relation.contact) xml += `<contact>${relation.contact}</contact>`;
        if(relation.rSwap) xml += `<rSwap>${relation.rSwap}</rSwap>`;
        if(relation.typeName) xml += `<typeName>${relation.typeName}</typeName>`;
        if(relation.type) xml += `<type>${relation.type}</type>`;
        if(relation.contact1Id) xml += `<contact1Id>${relation.contact1Id}</contact1Id>`;
        if(relation.contact2Id) xml += `<contact2Id>${relation.contact2Id}</contact2Id>`;
        if(relation.contact1Name1) xml += `<contact1Name1>${relation.contact1Name1}</contact1Name1>`;
        if(relation.contact1Name2) xml += `<contact1Name2>${relation.contact1Name2}</contact1Name2>`;
        if(relation.contact1Name3) xml += `<contact1Name3>${relation.contact1Name3}</contact1Name3>`;
        if(relation.contact2Name1) xml += `<contact2Name1>${relation.contact2Name1}</contact2Name1>`;
        if(relation.contact2Name2) xml += `<contact2Name2>${relation.contact2Name2}</contact2Name2>`;
        if(relation.contact2Name3) xml += `<contact2Name3>${relation.contact2Name3}</contact2Name3>`;
        if(relation.contact1Phone) xml += `<contact1Phone>${relation.contact1Phone}</contact1Phone>`;
        if(relation.contact2Phone) xml += `<contact2Phone>${relation.contact2Phone}</contact2Phone>`;
        if(relation.contact1Email) xml += `<contact1Email>${relation.contact1Email}</contact1Email>`;
        if(relation.contact2Email) xml += `<contact2Email>${relation.contact2Email}</contact2Email>`;
        if(relation.description) xml += `<description>${relation.description}</description>`;
        if(relation.relation) xml += `<relation>${relation.relation}</relation>`;
        if(relation.typeName1) xml += `<typeName1>${relation.typeName1}</typeName1>`;
        if(relation.typeName2) xml += `<typeName2>${relation.typeName2}</typeName2>`;
        if(relation.rtype) xml += `<rtype>${relation.rtype}</rtype>`;
        if(relation.c1created) xml += `<c1created>${relation.c1created}</c1created>`;
        if(relation.c1createdBy) xml += `<c1createdBy>${relation.c1createdBy}</c1createdBy>`;
        if(relation.c1modified) xml += `<c1modified>${relation.c1modified}</c1modified>`;
        if(relation.c1modifiedBy) xml += `<c1modifiedBy>${relation.c1modifiedBy}</c1modifiedBy>`;
        if(relation.c2created) xml += `<c2created>${relation.c2created}</c2created>`;
        if(relation.c2createdBy) xml += `<c2createdBy>${relation.c2createdBy}</c2createdBy>`;
        if(relation.c2modified) xml += `<c2modified>${relation.c2modified}</c2modified>`;
        if(relation.c2modifiedBy) xml += `<c2modifiedBy>${relation.c2modifiedBy}</c2modifiedBy>`;
        if(relation.contact1OldId) xml += `<contact1OldId>${relation.contact1OldId}</contact1OldId>`;
        if(relation.contact2OldId) xml += `<contact2OldId>${relation.contact2OldId}</contact2OldId>`;
        if(relation.SUBJECT) xml += toSubjectXml(relation.SUBJECT);
        xml += '</RELATION>';
    }

    return xml;
}

export interface Subject {
    id?:   string;
    name?: string;
    type?: string;
}

export function toSubjectXml(subject?: Subject): string {
    var xml = '';
    if(subject){
        xml += '<SUBJECT>';
        if(subject.id) xml += `<id>${subject.id}</id>`;
        if(subject.name) xml += `<name>${subject.name}</name>`;
        if(subject.type) xml += `<type>${subject.type}</type>`;
        xml += '</SUBJECT>';
    }
    return xml;
}

export interface ExternalContacts {
    count?:            string;
    EXTERNAL_CONTACT: ExternalContact[];
}

export function toExternalContactsXml(externalContacts?: ExternalContacts): string {
    var xml = '';
    if(externalContacts){
        xml += '<EXTERNAL_CONTACTS>';
        if(externalContacts.count) xml += `<count>${externalContacts.count}</count>`;
        if(externalContacts.EXTERNAL_CONTACT) externalContacts.EXTERNAL_CONTACT.forEach(externalContact => xml += toExternalContactXml(externalContact));
        xml += '</EXTERNAL_CONTACTS>';
    }
    return xml;
}

export interface ExternalContact {
    id?:                string;
    contextId?:         string;
    contactId?:         string;
    note?:              string;
    isPrimary?:         string;
    role?:              string;
    relationshipLevel?: string;
    deleteContact?:     string;
    name1?:             string;
    name2?:             string;
    name3?:             string;
    type?:              string;
    email?:             string;
    phone?:             string;
    mobile?:            string;
}

export function toExternalContactXml(externalContact?: ExternalContact): string {
    var xml = '';
    if(externalContact){
        xml += '<EXTERNAL_CONTACT>';
        if(externalContact.id) xml += `<id>${externalContact.id}</id>`;
        if(externalContact.contextId) xml += `<contextId>${externalContact.contextId}</contextId>`;
        if(externalContact.contactId) xml += `<contactId>${externalContact.contactId}</contactId>`;
        if(externalContact.note) xml += `<note>${externalContact.note}</note>`;
        if(externalContact.isPrimary) xml += `<isPrimary>${externalContact.isPrimary}</isPrimary>`;
        if(externalContact.role) xml += `<role>${externalContact.role}</role>`;
        if(externalContact.relationshipLevel) xml += `<relationshipLevel>${externalContact.relationshipLevel}</relationshipLevel>`;
        if(externalContact.deleteContact) xml += `<deleteContact>${externalContact.deleteContact}</deleteContact>`;
        if(externalContact.name1) xml += `<name1>${externalContact.name1}</name1>`;
        if(externalContact.name2) xml += `<name2>${externalContact.name2}</name2>`;
        if(externalContact.name3) xml += `<name3>${externalContact.name3}</name3>`;
        if(externalContact.type) xml += `<type>${externalContact.type}</type>`;
        if(externalContact.email) xml += `<email>${externalContact.email}</email>`;
        if(externalContact.phone) xml += `<phone>${externalContact.phone}</phone>`;
        if(externalContact.mobile) xml += `<mobile>${externalContact.mobile}</mobile>`;
        xml += '</EXTERNAL_CONTACT>';
    }
    return xml;
}

export interface Location {
    longitude?: string;
    latitude?:  string;
    TAG?:       string;
}

export function toLocationXml(location?: Location): string {
    var xml = '';
    if(location){
        xml += '<LOCATION>';
        if(location.longitude) xml += `<longitude>${location.longitude}</longitude>`;
        if(location.latitude) xml += `<latitude>${location.latitude}</latitude>`;
        if(location.TAG) xml += `<TAG>${location.TAG}</TAG>`;
        xml += '</LOCATION>';
    }
    return xml;
}

export interface Path {
    result?: string;
    NODE?:   Node;
}

export function toPathXml(path?: Path): string {
    var xml = '';
    if(path){
        xml += '<PATH>';    
        if(path.result) xml += `<result>${path.result}</result>`;
        if(path.NODE) xml += toNodeXml(path.NODE);
        xml += '</PATH>';
    }

    return xml;
}

export interface Node {
    id?:     string;
    name?:   string;
    module?: string;
    parent?: string;
    type?:   string;
    NODE?:   Node;
}

export function toNodeXml(node?: Node): string {
    var xml = '';
    if(node){
        xml += '<NODE>';    
        if(node.id) xml += `<id>${node.id}</id>`;
        if(node.name) xml += `<name>${node.name}</name>`;
        if(node.module) xml += `<module>${node.module}</module>`;
        if(node.parent) xml += `<parent>${node.parent}</parent>`;
        if(node.type) xml += `<type>${node.type}</type>`;
        if(node.NODE) xml += toNodeXml(node.NODE);
        xml += '</NODE>';
    }

    return xml;
}
export interface Projects {
    PROJECT: Project[];
}

export function toProjectsXml(projects?: Projects): string {
    var xml = '';
    if(projects){
        xml += '<PROJECTS>';
        if(projects.PROJECT) projects.PROJECT.forEach(project => xml += toProjectXml(project));
        xml += '</PROJECTS>';
    }
    return xml;
}

export interface Project {
    aclh?:                        string;
    archived?:                    string;
    comment?:                     string;
    created?:                     Date;
    createdBy?:                   string;
    createdByName?:               string;
    customer?:                    string;
    customerName?:                string;
    customState?:                 string;
    customStateName?:             string;
    customStateColor?:            string;
    chargeable?:                  string;
    defaultMessageContainer?:     string;
    defaultMessageContainerPath?: string;
    defaultMessageGroup?:         string;
    dimension1?:                  string;
    dimension1Name?:              string;
    dimension2?:                  string;
    dimension2Name?:              string;
    dimension3?:                  string;
    dimension3Name?:              string;
    folder?:                      string;
    folderName?:                  string;
    folderType?:                  string;
    folderTypeName?:              string;
    formId?:                      string;
    formName?:                    string;
    formValues?:                  string;
    guiAction?:                   string;
    id?:                          string;
    internalFolder?:              string;
    invoiceJournal?:              string;
    invoiceJournalName?:          string;
    isMasterProject?:             string;
    keepModified?:                string;
    firstContactDate?:            string;
    lastContactDate?:             string;
    lastDocDate?:                 string;
    masterFolderId?:              string;
    masterFolderName?:            string;
    masterFolderTypeName?:        string;
    masterProjectId?:             string;
    masterProjectName?:           string;
    masterProjectTypeName?:       string;
    contractPlanId?:              string;
    currentPlanId?:               string;
    modified?:                    Date;
    modifiedBy?:                  string;
    modifiedByName?:              string;
    name?:                        string;
    nameInMaster?:                string;
    nameSearch?:                  string;
    notificationId?:              string;
    organization?:                string;
    pricing?:                     string;
    pricingName?:                 string;
    primaryContact?:              string;
    primaryContactName?:          string;
    projectTemplate?:             string;
    projectType?:                 string;
    projectTypeName?:             string;
    provider?:                    string;
    providerName?:                string;
    refId?:                       string;
    responsible?:                 string;
    responsibleName?:             string;
    state?:                       string;
    team?:                        string;
    templateAclh?:                string;
    treeHandle?:                  string;
    typeFormId?:                  string;
    priority?:                    string;
    rtsProjectHash?:              string;
    sla?:                         string;
    itemPricing?:                 string;
    itemPricingName?:             string;
    trackingCode?:                string;
    contextParent?:               string;
    contextParentName?:           string;
    item?:                        string;
    EXTERNAL_CONTACTS?:           ExternalContacts;
    PRIMARYCONTACT?:              Contact;
    LOCATION?:                    Location;
    EXTERNAL_IDS?:                ExternalIDS;
}

export function toProjectXml(project?: Project): string {
    var xml = '';
    if(project){
        xml += '<PROJECT>';
        if(project.aclh) xml += `<aclh>${project.aclh}</aclh>`;
        if(project.archived) xml += `<archived>${project.archived}</archived>`;
        if(project.comment) xml += `<comment>${project.comment}</comment>`;
        if(project.created) xml += `<created>${project.created}</created>`;
        if(project.createdBy) xml += `<createdBy>${project.createdBy}</createdBy>`;
        if(project.createdByName) xml += `<createdByName>${project.createdByName}</createdByName>`;
        if(project.customer) xml += `<customer>${project.customer}</customer>`;
        if(project.customerName) xml += `<customerName>${project.customerName}</customerName>`;
        if(project.customState) xml += `<customState>${project.customState}</customState>`;
        if(project.customStateName) xml += `<customStateName>${project.customStateName}</customStateName>`;
        if(project.customStateColor) xml += `<customStateColor>${project.customStateColor}</customStateColor>`;
        if(project.chargeable) xml += `<chargeable>${project.chargeable}</chargeable>`;
        if(project.defaultMessageContainer) xml += `<defaultMessageContainer>${project.defaultMessageContainer}</defaultMessageContainer>`;
        if(project.defaultMessageContainerPath) xml += `<defaultMessageContainerPath>${project.defaultMessageContainerPath}</defaultMessageContainerPath>`;
        if(project.defaultMessageGroup) xml += `<defaultMessageGroup>${project.defaultMessageGroup}</defaultMessageGroup>`;
        if(project.dimension1) xml += `<dimension1>${project.dimension1}</dimension1>`;
        if(project.dimension1Name) xml += `<dimension1Name>${project.dimension1Name}</dimension1Name>`;
        if(project.dimension2) xml += `<dimension2>${project.dimension2}</dimension2>`;
        if(project.dimension2Name) xml += `<dimension2Name>${project.dimension2Name}</dimension2Name>`;
        if(project.dimension3) xml += `<dimension3>${project.dimension3}</dimension3>`;
        if(project.dimension3Name) xml += `<dimension3Name>${project.dimension3Name}</dimension3Name>`;
        if(project.folder) xml += `<folder>${project.folder}</folder>`;
        if(project.folderName) xml += `<folderName>${project.folderName}</folderName>`;
        if(project.folderType) xml += `<folderType>${project.folderType}</folderType>`;
        if(project.folderTypeName) xml += `<folderTypeName>${project.folderTypeName}</folderTypeName>`;
        if(project.formId) xml += `<formId>${project.formId}</formId>`;
        if(project.formName) xml += `<formName>${project.formName}</formName>`;
        if(project.formValues) xml += `<formValues>${project.formValues}</formValues>`;
        if(project.guiAction) xml += `<guiAction>${project.guiAction}</guiAction>`;
        if(project.id) xml += `<id>${project.id}</id>`;
        if(project.internalFolder) xml += `<internalFolder>${project.internalFolder}</internalFolder>`;
        if(project.invoiceJournal) xml += `<invoiceJournal>${project.invoiceJournal}</invoiceJournal>`;
        if(project.invoiceJournalName) xml += `<invoiceJournalName>${project.invoiceJournalName}</invoiceJournalName>`;
        if(project.isMasterProject) xml += `<isMasterProject>${project.isMasterProject}</isMasterProject>`;
        if(project.keepModified) xml += `<keepModified>${project.keepModified}</keepModified>`;
        if(project.firstContactDate) xml += `<firstContactDate>${project.firstContactDate}</firstContactDate>`;
        if(project.lastContactDate) xml += `<lastContactDate>${project.lastContactDate}</lastContactDate>`;
        if(project.lastDocDate) xml += `<lastDocDate>${project.lastDocDate}</lastDocDate>`;
        if(project.masterFolderId) xml += `<masterFolderId>${project.masterFolderId}</masterFolderId>`;
        if(project.masterFolderName) xml += `<masterFolderName>${project.masterFolderName}</masterFolderName>`;
        if(project.masterFolderTypeName) xml += `<masterFolderTypeName>${project.masterFolderTypeName}</masterFolderTypeName>`;
        if(project.masterProjectId) xml += `<masterProjectId>${project.masterProjectId}</masterProjectId>`;
        if(project.masterProjectName) xml += `<masterProjectName>${project.masterProjectName}</masterProjectName>`;
        if(project.masterProjectTypeName) xml += `<masterProjectTypeName>${project.masterProjectTypeName}</masterProjectTypeName>`;
        if(project.contractPlanId) xml += `<contractPlanId>${project.contractPlanId}</contractPlanId>`;
        if(project.currentPlanId) xml += `<currentPlanId>${project.currentPlanId}</currentPlanId>`;
        if(project.modified) xml += `<modified>${project.modified}</modified>`;
        if(project.modifiedBy) xml += `<modifiedBy>${project.modifiedBy}</modifiedBy>`;
        if(project.modifiedByName) xml += `<modifiedByName>${project.modifiedByName}</modifiedByName>`;
        if(project.name) xml += `<name>${project.name}</name>`;
        if(project.nameInMaster) xml += `<nameInMaster>${project.nameInMaster}</nameInMaster>`;
        if(project.nameSearch) xml += `<nameSearch>${project.nameSearch}</nameSearch>`;
        if(project.notificationId) xml += `<notificationId>${project.notificationId}</notificationId>`;
        if(project.organization) xml += `<organization>${project.organization}</organization>`;
        if(project.pricing) xml += `<pricing>${project.pricing}</pricing>`;
        if(project.pricingName) xml += `<pricingName>${project.pricingName}</pricingName>`;
        if(project.primaryContact) xml += `<primaryContact>${project.primaryContact}</primaryContact>`;
        if(project.primaryContactName) xml += `<primaryContactName>${project.primaryContactName}</primaryContactName>`;
        if(project.projectTemplate) xml += `<projectTemplate>${project.projectTemplate}</projectTemplate>`;
        if(project.projectType) xml += `<projectType>${project.projectType}</projectType>`;
        if(project.projectTypeName) xml += `<projectTypeName>${project.projectTypeName}</projectTypeName>`;
        if(project.provider) xml += `<provider>${project.provider}</provider>`;
        if(project.providerName) xml += `<providerName>${project.providerName}</providerName>`;
        if(project.refId) xml += `<refId>${project.refId}</refId>`;
        if(project.responsible) xml += `<responsible>${project.responsible}</responsible>`;
        if(project.responsibleName) xml += `<responsibleName>${project.responsibleName}</responsibleName>`;
        if(project.state) xml += `<state>${project.state}</state>`;
        if(project.team) xml += `<team>${project.team}</team>`;
        if(project.templateAclh) xml += `<templateAclh>${project.templateAclh}</templateAclh>`;
        if(project.treeHandle) xml += `<treeHandle>${project.treeHandle}</treeHandle>`;
        if(project.typeFormId) xml += `<typeFormId>${project.typeFormId}</typeFormId>`;
        if(project.priority) xml += `<priority>${project.priority}</priority>`;
        if(project.rtsProjectHash) xml += `<rtsProjectHash>${project.rtsProjectHash}</rtsProjectHash>`;
        if(project.sla) xml += `<sla>${project.sla}</sla>`;
        if(project.itemPricing) xml += `<itemPricing>${project.itemPricing}</itemPricing>`;
        if(project.itemPricingName) xml += `<itemPricingName>${project.itemPricingName}</itemPricingName>`;
        if(project.trackingCode) xml += `<trackingCode>${project.trackingCode}</trackingCode>`;
        if(project.contextParent) xml += `<contextParent>${project.contextParent}</contextParent>`;
        if(project.contextParentName) xml += `<contextParentName>${project.contextParentName}</contextParentName>`;
        if(project.item) xml += `<item>${project.item}</item>`;
        if(project.EXTERNAL_CONTACTS) xml += toExternalContactsXml(project.EXTERNAL_CONTACTS);
        if(project.PRIMARYCONTACT) xml += toContactXml(project.PRIMARYCONTACT);
        if(project.LOCATION) xml += toLocationXml(project.LOCATION);
        if(project.EXTERNAL_IDS) xml += toExternalIdsXml(project.EXTERNAL_IDS);
        xml += '</PROJECT>';
    }
    return xml;
}
