import { LayoutComponent } from '../LayoutComponent';
import { Content, Flex, Link, TextField, View } from '@adobe/react-spectrum';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { GetProjectRequest } from '../../../../services/soap/project/requests/GetProjectRequest';
import { GetFolderRequest } from '../../../../services/soap/project/requests/GetFolderRequest';
import { UpdateProjectRequest } from '../../../../services/soap/project/requests/UpdateProjectRequest';
import { UpdateFolderRequest } from '../../../../services/soap/project/requests/UpdateFolderRequest';
import { UpdateActivityRequest } from '../../../../services/soap/project/requests/UpdateActivityRequest';
import { ToastQueue } from '@react-spectrum/toast';
import styles from './ContextCommentComponent.module.css';

export interface IContextCommentComponentProps {
  commentTitle?: string;
}

function ContextComment({ commentTitle }: IContextCommentComponentProps) {
  const [disabled, setDisabled] = useState(true);
  const [comment, setComment] = useState('');
  const item_info = useRef<any>(null);
  const { projectService, store } = useDependency();
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);

  const loadComment = useCallback(async () => {
    if (selectedItem) {
      switch (selectedItem.type) {
        case FPADataTypes.ACTIVITY:
          let activity = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, selectedItem.id));
          item_info.current = activity.ACTIVITY;
          setComment(activity.ACTIVITY.comment!);
          break;
        case FPADataTypes.PROJECT:
          let { PROJECT } = await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, selectedItem?.id));
          item_info.current = PROJECT;
          setComment(PROJECT.comment!);
          break;
        case FPADataTypes.FOLDER:
          let { FOLDER } = await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, selectedItem.id));
          item_info.current = FOLDER;
          setComment(FOLDER.comment!);
          break;
      }
    }
  }, [selectedItem]);

  const saveComment = useCallback(
    async (_comment: string) => {
      if (selectedItem) {
        switch (selectedItem.type) {
          case FPADataTypes.ACTIVITY:
            //console.log('saving comment: ', _comment);
            let activity_save = await projectService.updateActivity(new UpdateActivityRequest(store.Server, store.SessionId, { ...item_info.current!, comment: _comment }));
            if (activity_save.result !== 'OK') {
              ToastQueue.negative('Error saving comment');
            }
            break;
          case FPADataTypes.PROJECT:
            let project_save = await projectService.updateProject(new UpdateProjectRequest(store.Server, store.SessionId, { ...item_info.current!, comment: _comment }));
            if (project_save.result !== 'OK') {
              ToastQueue.negative('Error saving comment');
            }
            break;
          case FPADataTypes.FOLDER:
            let folder_save = await projectService.updateFolder(new UpdateFolderRequest(store.Server, store.SessionId, { ...item_info.current!, comment: _comment }));
            if (folder_save.result !== 'OK') {
              ToastQueue.negative('Error saving comment');
            }
            break;
        }
      }
    },
    [selectedItem]
  );

  useEffect(() => {
    loadComment();
  }, [loadComment]);
  const handleSave = async () => {
    await saveComment(comment);
    ToastQueue.positive('Comment saved successfully', { timeout: 3000 });
    setDisabled(true);
  };

  const handleCancel = () => {
    setComment(item_info.current?.comment!);
    setDisabled(true);
  };

  const handleClick = () => {
    setDisabled(false);
  };
  return (
    <>
      <Flex direction={'column'} width={'100%'} position={'relative'} alignItems={'start'} justifyContent={'start'} gap={'16px'} UNSAFE_className={styles.comment_heading_parent}>
        <Content alignSelf={'stretch'} position={'relative'} UNSAFE_className={styles.commnet_heading_text}>
          {commentTitle}
        </Content>
        <Flex direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.comment_box_wrapper}>
          <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} width={'100%'}>
            <div style={{ width: '100%' }} onClick={handleClick}>
              <TextField aria-label="test" width="100%" value={comment} onChange={e => setComment(e)} UNSAFE_className={styles.comment_box} />
            </div>
          </Flex>
          {!disabled && (
            <Flex width={'100%'} marginTop={'size-200'} direction="row" gap="size-100" justifyContent={'end'}>
              <Link isQuiet onPress={handleCancel} UNSAFE_className={styles.btn_cancel}>
                Cancel
              </Link>
              <Link isQuiet onPress={handleSave} UNSAFE_className={styles.btn_confirm}>
                Save
              </Link>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
}

export const ContextCommentComponent = LayoutComponent(ContextComment);
