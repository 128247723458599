import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { TaskItem, toTaskItemXML } from "./TaskItem";

export interface ICreateTaskRequest extends IBaseRequest {
    ITEM:      TaskItem;
}

export class CreateTaskRequest extends BaseRequest implements ICreateTaskRequest {
    public ITEM: TaskItem;
  constructor(server: string, session: string, item: TaskItem) {
    super(server, session);
    this.ITEM = item;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += '<ITEM>';
    parameters += toTaskItemXML(this.ITEM);
    parameters += '</ITEM>';
    return super.wrapperXml('CreateTask', parameters);
  }
}
