import { TaskItem } from "./TaskItem";

export function generateTaskItem( 
    activityId: string,
    projectId: string,
    folderId: string,
    userId: string,
    title: string,
    typeOfWorkId: string,
    solverId: string,
    solutionStatusId: string,
    u_item: TaskItem): TaskItem{
        var item = { 
            ...u_item,
            activityId: activityId,
            projectId: projectId,
            folderId: folderId,
            // solutionStatus: '131816081',
            solutionStatus: solutionStatusId,
            userId: userId,
            title: title,
            entryType: typeOfWorkId,
            forUser: solverId,
            dateFrom: new Date().toISOString(),
            dateTo: new Date().toISOString(),
            USER: { id: userId },
            headUserId: userId,
            GARANT: { userId: solverId }
        };
    return item;
}