import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Cell, Column, Row, TableView, TableBody, TableHeader, Flex, Content, View, Link, ProgressCircle, useAsyncList } from '@adobe/react-spectrum';
import moment from 'moment';
import TimesheetLineAddEdit from './TimesheetLineAddEdit';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { ToastQueue } from '@react-spectrum/toast';
import { ListTimesheetRequest } from '../../../../services/soap/scheduler/requests/ListTimesheetRequest';
import { GetUserRequest } from '../../../../services/soap/access/requests/GetUserRequest';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import { UpdateTimesheetRequest } from '../../../../services/soap/scheduler/requests/UpdateTimesheetRequest';
import { EvaluateTimesheetRequest } from '../../../../services/soap/scheduler/requests/EvaluateTimesheetRequest';
import { DeleteTimesheetRequest } from '../../../../services/soap/scheduler/requests/DeleteTimesheetRequest';
import { CreateTimesheetRequest } from '../../../../services/soap/scheduler/requests/CreateTimesheetRequest';
import { generateTimeSheetItem } from '../../../../services/soap/scheduler/requests/CreateTimesheetOptions';
import { GetTypeOfWorkForContextRequest } from '../../../../services/soap/scheduler/requests/GetTypeOfWorkForContextRequest';
import { GetSchedulerSettingsRequest, SettingsItemType } from '../../../../services/soap/scheduler/requests/GetSchedulerSettingsRequest';
import { ListUserRequest } from '../../../../services/soap/access/requests/ListUserRequest';
import useComonentReload from '../../../../hooks/UseComponentReload';
import styles from './TimesheetLineComponent.module.css';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { t } from 'i18next';

interface TimesheetLineProps {}

export interface RowData {
  ident: number;
  userId: string;
  workerName: string;
  typeOfWorkId: string;
  typeOfWorkName: string;
  entryDateFormatted: string;
  entryDate: string;
  hours: string;
  minutes: string;
  totalMinutes: string;
  formattedTime: string;
  chargeable: string;
}
interface Character {
  workerName: string;
  typeOfWorkName: string;
  entryDateFormatted: string;
  formattedTime: string;
  chargeable: string;
}
type typeTimesheetLineParam = {
  limit?: number;
  offset?: number;
  etypeFilter: string;
  statusFilter: string;
  chargeFilter: string;
  typeOfWorkFilter: string;
  countOnly?: string;
  FOLDERS: {
    count: string;
    FOLDER: {
      folderId: string;
      projectId?: string;
      activityId?: string;
    };
  };
};

function TimesheetLine(props: TimesheetLineProps) {
  const PAGE_SIZE: number = 50;
  let columnsData = [
    { name: 'Worker', key: 'workerName' },
    { name: 'Type of work', key: 'typeOfWorkName' },
    { name: 'Date', key: 'entryDateFormatted' },
    { name: 'Hours', key: 'formattedTime' },
    { name: 'Chargeable', key: 'chargeable', width: 100 },
  ];

  let columnsDataMobile = [
    { name: 'Worker', key: 'workerName', width: 250 },
    { name: 'Type of work', key: 'typeOfWorkName', width: 250 },
    { name: 'Date', key: 'entryDateFormatted', width: 150 },
    { name: 'Hours', key: 'formattedTime', width: 150 },
    { name: 'Chargeable', key: 'chargeable', width: 150 },
  ];

  let defaultRowData: RowData = {
    ident: 0,
    userId: '',
    workerName: '',
    typeOfWorkId: '',
    typeOfWorkName: '',
    entryDateFormatted: '',
    entryDate: '',
    hours: '',
    minutes: '',
    totalMinutes: '',
    formattedTime: '',
    chargeable: 'Yes',
  };

  const [reloadComponent] = useComonentReload();
  const { accessService, schedulerService, projectService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const [timesheetLineData, setTimesheetLineData] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any>([]);
  const [totalTimeSheetRec, setTotalTimeSheetRec] = useState<number | undefined>(undefined);
  const [selectedRow, setSelectedRow] = useState<RowData>(defaultRowData);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showAddEditRow, setShowAddEditRow] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [typeofWorkList, setTypeofWorkList] = useState<any>([]);
  const [userData, setUserData] = useState<any>([]);
  const dataRef = useRef<any>({});

  useEffect(() => {
    setShowAddEditRow(false);
    (async () => {
      await getTypeofWorkList();
      getUserCount().then((totalRecords: any) => getUserData(totalRecords));
      const { LIST } = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, Number(selectedItem?.id)));
      //console.log('contextRespone', contextRespone);
      const folderID = selectedItem ? LIST[selectedItem.type === FPADataTypes.ACTIVITY ? 1 : 0]?.CONTEXT[selectedItem.type === FPADataTypes.FOLDER ? 'id' : 'parentId'] : undefined;
      //console.log('folderID', folderID);
      dataRef.current = { folderID };
      setShowLoader(false);
    })();
  }, [selectedItem]);

  useEffect(() => {
    listTimeSheetData.reload();
  }, [reloadComponent, selectedItem]);

  const getUserCount = async () => {
    // Retrieve selected item
    //console.log('selectedItem', selectedItem);

    // Call API to fetch timesheet line data
    let userList = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, undefined, undefined, '1'));

    //console.log('timesheetResp.ITEMS[0].ITEM.totalRecords', timesheetResp.ITEMS[0].ITEM.totalRecords);

    // If API call is successful
    if (userList.result === 'OK') {
      return userList.count;
    }
  };

  const getUserData = async (totalRecords: number) => {
    let userResp = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, totalRecords));

    if (userResp.result === 'OK') {
      const formattedArray = userResp.USERS.map(({ USER }) => ({
        id: USER.id,
        name: `${USER.name1} ${USER.name3}`,
      }));
      setUserData(formattedArray);
    }
  };

  const getTypeofWorkList = async () => {
    let scheduleResp = await schedulerService.getSchedulerSettings(new GetSchedulerSettingsRequest(store.Server, store.SessionId, '4', SettingsItemType.TIME_SHEET_ITEM_TYPE, 50, 0));
    //console.log('scheduleResp', scheduleResp);

    let typeofWorkContextResp: any = await schedulerService.getTypeOfWorkForContext(new GetTypeOfWorkForContextRequest(store.Server, store.SessionId, false));
    //console.log('typeofWorkContextResp', typeofWorkContextResp);

    const filteredTypeofWork = scheduleResp.ITEMS.filter(item => typeofWorkContextResp.SETTINGS.some((setting: any) => setting.SETTING.typeOfWork === item.ITEM.ident));
    //console.log('filteredTypeofWork', filteredTypeofWork);

    setTypeofWorkList(filteredTypeofWork);
  };

  const getTimeSheetLineParams = () => {
    const timeSheetLineParams = {
      limit: 0,
      offset: 0,
      etypeFilter: '1',
      statusFilter: '0',
      chargeFilter: '0',
      typeOfWorkFilter: '0',
      countOnly: 'true',
      FOLDERS: {
        count: '1',
        FOLDER: {
          folderId: '',
          projectId: '',
          activityId: '',
        },
      },
    } as typeTimesheetLineParam;

    switch (selectedItem?.type) {
      case FPADataTypes.ACTIVITY:
        timeSheetLineParams.FOLDERS.FOLDER.folderId = dataRef.current.folderID;
        timeSheetLineParams.FOLDERS.FOLDER.projectId = String(selectedItem?.parent_id);
        timeSheetLineParams.FOLDERS.FOLDER.activityId = String(selectedItem?.id);
        break;
      case FPADataTypes.PROJECT:
        timeSheetLineParams.FOLDERS.FOLDER.folderId = String(selectedItem?.parent_id);
        timeSheetLineParams.FOLDERS.FOLDER.projectId = String(selectedItem?.id);
        delete timeSheetLineParams.FOLDERS.FOLDER.activityId;
        break;
      case FPADataTypes.FOLDER:
        timeSheetLineParams.FOLDERS.FOLDER.folderId = String(selectedItem?.id);
        delete timeSheetLineParams.FOLDERS.FOLDER.activityId;
        delete timeSheetLineParams.FOLDERS.FOLDER.projectId;
        break;
    }

    return timeSheetLineParams;
  };

  const getTimesheetLineCount = async () => {
    // Retrieve selected item
    //console.log('selectedItem', selectedItem);

    const timeSheetLineParams: typeTimesheetLineParam = getTimeSheetLineParams();
    delete timeSheetLineParams.limit;
    delete timeSheetLineParams.offset;
    // Call API to fetch timesheet line data
    let timesheetResp: any = await schedulerService.listTimeSheet(new ListTimesheetRequest(store.Server, store.SessionId, { ...timeSheetLineParams }));
    //console.log('ITEM.totalRecords', timesheetResp.ITEMS[0].ITEM.totalRecords);

    // If API call is successful
    if (timesheetResp.result === 'OK') {
      setTotalTimeSheetRec(timesheetResp.ITEMS[0].ITEM.totalRecords != 0 ? timesheetResp.ITEMS[0].ITEM.totalRecords : undefined);
      return timesheetResp.ITEMS[0].ITEM.totalRecords != 0 ? timesheetResp.ITEMS[0].ITEM.totalRecords : undefined;
    }
  };

  let listTimeSheetData = useAsyncList<Character>({
    async load({ cursor }) {
      //console.log('totalTimeSheetRec', totalTimeSheetRec);
      let totalRecords = totalTimeSheetRec ?? (await getTimesheetLineCount());
      //console.log('totalRecords', totalRecords);
      let currentPage = cursor ? parseInt(cursor) : 0;
      //console.log('currentPage', currentPage);
      let offset = PAGE_SIZE * currentPage;
      //console.log('offset', offset);
      if (isNaN(offset) || offset >= totalRecords) {
        setShowLoader(false);
        return { items: [], cursor: undefined };
      }

      const timeSheetLineParams: typeTimesheetLineParam = getTimeSheetLineParams();

      let timesheetResp = await schedulerService.listTimeSheet(
        new ListTimesheetRequest(store.Server, store.SessionId, {
          ...timeSheetLineParams,
          limit: PAGE_SIZE,
          offset: offset,
          countOnly: undefined,
        })
      );

      if (timesheetResp.result === 'OK') {
        //console.log('timesheetResp', timesheetResp);
        setTimesheetLineData(timesheetResp.ITEMS);
        let timesheetLineData = timesheetResp.ITEMS.map(item => ({
          id: item.ITEM.ident,
          userId: item.ITEM.userId,
          workerName: '-',
          typeOfWorkId: item.ITEM.typeOfWorkId,
          typeOfWorkName: item.ITEM.typeOfWorkName,
          entryDateFormatted: moment(item.ITEM.entryDate).format('MM/DD/YYYY'),
          entryDate: moment(item.ITEM.entryDate).format('YYYY-MM-DD'),
          totalMinutes: item.ITEM.minutes,
          formattedTime: convertMinutesToHHMM(item.ITEM.minutes),
          hours: convertMinutesToHHMM(item.ITEM.minutes).split('h')[0],
          minutes: convertMinutesToHHMM(item.ITEM.minutes).split('h')[1].split('m')[0],
          chargeable: item.ITEM.chargeable === '1' ? 'Yes' : 'No',
        }));
        return updateTimesheetLineData(timesheetLineData).then(updatedData => {
          //console.log('updatedData', updatedData);
          setRowsData(updatedData);
          setShowLoader(false);
          //console.log('String(offset >= totalRecords ? undefined : currentPage + 1)', String(offset >= totalRecords ? undefined : currentPage + 1));
          //console.log('updatedData', updatedData);
          return {
            items: updatedData,
            cursor: String(offset >= totalRecords ? undefined : currentPage + 1),
          };
        });
      } else {
        setShowLoader(false);
        return { items: [], cursor: undefined };
      }
    },
  });

  const convertMinutesToHHMM = (minutes: string | undefined): string => {
    if (minutes === undefined) {
      return '00h 00m';
    }
    const hours = Math.floor(Number(minutes) / 60);
    const mins = Number(minutes) % 60;
    return `${hours.toString().padStart(2, '0')}h ${mins.toString().padStart(2, '0')}m`;
  };

  const updateTimesheetLineData = async (timesheetLineData: any[]) => {
    const promises = timesheetLineData?.map(async (item: any) => ({
      ...item,
      workerName: userData.find((user: any) => user.id === item.userId)?.name ?? (await getWorkerName(item)),
    }));
    const updatedBillingLineData = await Promise.all(promises);
    return updatedBillingLineData;
  };

  const getWorkerName = async (item: any) => {
    //console.log('item', item);
    let userResp = await accessService.getUser(new GetUserRequest(store.Server, store.SessionId, item.userId));
    //console.log('itemResp', itemResp);
    return userResp.USER.name1 + ' ' + userResp.USER.name3;
  };

  const handleRowClick = (rowId: any): void => {
    //const isSelectedRowNotEmpty = selectedRow && Object.keys(selectedRow).length > 0;
    //console.log('rowId', rowId.currentKey);
    //console.log('rowsData', rowsData);
    const rowData: any = rowsData.find((row: any) => row.id === rowId.currentKey);
    //console.log('rowData', rowData);
    setSelectedRow(rowData);
    setShowAddEditRow(true);
    setSelectedKeys(rowId);
  };

  const handleRowClose = (): void => {
    setSelectedRow(defaultRowData);
    setShowAddEditRow(false);
    handleUnselect();
  };

  const handleUnselect = () => {
    setSelectedKeys(new Set());
  };

  const addRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('addRecord', record);

      var result = await schedulerService.createTimeSheet(
        new CreateTimesheetRequest(
          store.Server,
          store.SessionId,
          generateTimeSheetItem(
            record.userId,
            '',
            record.typeOfWorkId,
            '',
            Number(record.hours.trim()) * 60 + Number(record.minutes.trim()),
            String(selectedItem?.id),
            '',
            String(selectedItem?.parent_id),
            '',
            dataRef.current.folderID,
            '',
            record.chargeable === 'Yes' ? true : false,
            new Date(record.entryDate)
          )
        )
      );

      //console.log('addRecord result', result);

      if (result.result === 'OK') {
        await schedulerService.evaluateTimeSheet(new EvaluateTimesheetRequest(store.Server, store.SessionId, { recordId: result.itemId }));
        await getTimesheetLineCount();
        handleRowClose();
        listTimeSheetData.reload();

        ToastQueue.positive('Record saved successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error: any) {
      ToastQueue.negative(error.message, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const updateRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('timesheetLineData', timesheetLineData);
      //console.log('update record', record);
      let itemRow = timesheetLineData.find((row: any) => row.ITEM.ident == record.id);
      //console.log('itemRow', itemRow);
      //console.log('Number(record.hours.trim()) * 60 + Number(record.minutes.trim())', Number(record.hours.trim()) * 60 + Number(record.minutes.trim()));
      let result = await schedulerService.updateTimeSheet(
        new UpdateTimesheetRequest(store.Server, store.SessionId, {
          ...itemRow.ITEM,
          chargeable: record.chargeable === 'Yes' ? '1' : '0',
          entryDate: moment(record.entryDate).format('YYYY-MM-DDTHH:mm:ss'),
          minutes: Number(record.hours.trim()) * 60 + Number(record.minutes.trim()),
          userId: record.userId,
          typeOfWorkId: record.typeOfWorkId,
        })
      );

      // console.log('result', result);
      if (result.result === 'OK') {
        //console.log('result', result);
        await schedulerService.evaluateTimeSheet(new EvaluateTimesheetRequest(store.Server, store.SessionId, { recordId: result.itemId }));
        await getTimesheetLineCount();
        handleRowClose();
        listTimeSheetData.reload();
        ToastQueue.positive('Record updated successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const deleteRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('record', record);
      let result = await schedulerService.deleteTimeSheet(new DeleteTimesheetRequest(store.Server, store.SessionId, record.id));
      //console.log('result', result);
      if (result.result === 'OK') {
        await getTimesheetLineCount();
        handleRowClose();
        listTimeSheetData.reload();
        ToastQueue.positive('Record deleted successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            Time Sheet
          </Content>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <Link
                isQuiet
                onPress={e => {
                  defaultRowData.entryDate = new Date().toISOString();
                  defaultRowData.userId = String(store.UserId);
                  setSelectedRow(defaultRowData);
                  handleUnselect();
                  setShowAddEditRow(true);
                }}
              >
                <i className="bi bi-plus fs-5">
                  <View UNSAFE_className={styles.icon_add_text}>Add Time</View>
                </i>
              </Link>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            <TableView
              aria-label="timesheet line"
              onSelectionChange={handleRowClick}
              selectionMode="single"
              selectedKeys={selectedKeys}
              selectionStyle="highlight"
              width={{ base: '100%', L: '100%', M: '100%' }}
              minHeight={'80px'}
              maxHeight={{ base: '1000px', L: '450px' }}
              marginBottom={'size-250'}
            >
              <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                {(column: any) => (
                  <Column showDivider width={column?.width}>
                    {column.name}
                  </Column>
                )}
              </TableHeader>
              <TableBody items={listTimeSheetData.items} loadingState={listTimeSheetData.loadingState} onLoadMore={listTimeSheetData.loadMore}>
                {item => <Row>{(columnKey: any) => <Cell>{(item as any)[columnKey] === 'Yes' ? <strong>{(item as any)[columnKey]}</strong> : (item as any)[columnKey]}</Cell>}</Row>}
              </TableBody>
            </TableView>
          </Flex>
          {showAddEditRow && (
            <TimesheetLineAddEdit
              selectedItem={selectedItem}
              id={String(selectedItem?.id)}
              defaultRowData={defaultRowData}
              typeofWorkList={typeofWorkList}
              userData={userData}
              selectedRowData={selectedRow}
              handleRowClose={handleRowClose}
              addRecord={addRecord}
              updateRecord={updateRecord}
              deleteRecord={deleteRecord}
              styles={styles}
            />
          )}
        </Flex>
      </Flex>
    );
  }
}

export const TimesheetLineComponent = LayoutComponent(TimesheetLine);
