import { useCallback, useEffect, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Button, Flex, View } from '@adobe/react-spectrum';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { GetProjectRequest } from '../../../../services/soap/project/requests/GetProjectRequest';
import { GetFolderRequest } from '../../../../services/soap/project/requests/GetFolderRequest';
import { ListFormsRequest } from '../../../../services/soap/form/requests/ListFormsRequest';
import { FormDefinition } from './components/FormDefinitions';
import { SoapUtils } from '../../../../utils/SoapUtils';
import { FormControl } from './components/FormControl';
import { ListItemValueRequest } from '../../../../services/soap/form/requests/ListItemValueRequest';
import { Rows } from '../../../../services/soap/form/responses/ListItemValueResponse';
import { ItemValue, UpdateItemValueRequest } from '../../../../services/soap/form/requests/UpdateItemValueRequest';
import { ToastQueue } from '@react-spectrum/toast';

export interface IFormViewProps {
    formId?: string;
    formIdMode?: string;
    isSaveEnabled?: boolean;
    isSaveOnRightSide?: boolean;    
}

function FormView ({
    formId,
    formIdMode,
    isSaveEnabled,
    isSaveOnRightSide
}: IFormViewProps) {
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const { 
    formService,
    projectService, 
    store 
  } = useDependency();
  const [formDefinition, setFormDefinition] = useState<FormDefinition|null>(null);
  const [formData, setFormData] = useState<Map<string, any>>(new Map<string, any>());

  const handleSaveForm = async () => {
      const valuesArray = Array.from(formData.values());

      const passed_values = valuesArray.map((value: any) => {
        return new ItemValue( value.id, value.acl, value.formInfo, value.formItem, value.formItemName, value.value, value.typ, value.context);
      });

      var save_response = await formService.updateItemValue(new UpdateItemValueRequest(store.Server, store.SessionId, passed_values));
      if(save_response.result === 'OK') {
        ToastQueue.positive('Form saved successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative('Error saving form', { timeout: 3000 });
      }
  };

  const loadFormCallback = useCallback(async () => {
    if(selectedItem === null || selectedItem === undefined) return;
    if(selectedItem.type === FPADataTypes.FOLDER_TYPE || selectedItem.type === FPADataTypes.ITEM_TYPE) return;
    
    //console.log('FormView selectedItem', selectedItem);

    var form_id = null; 
    var formValuesId = null;
    switch(selectedItem.type) {
        case FPADataTypes.ACTIVITY:
          var activity = (await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, selectedItem.id))).ACTIVITY;
          form_id = formIdMode === 'template' ? activity.formId : formId;
          formValuesId = activity.formValues;
          break;
        case FPADataTypes.PROJECT:
          var project = (await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, selectedItem.id))).PROJECT;
          form_id = formIdMode === 'template' ? project.formId : formId;
          formValuesId = project.formValues;
          break;
        case FPADataTypes.FOLDER:
          var folder = (await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, selectedItem.id))).FOLDER;
          form_id = formIdMode === 'template' ? folder.formId : formId;
          formValuesId = folder.formValues;
          break;
    }
    
    var form = await formService.listForms(new ListFormsRequest(store.Server, store.SessionId, form_id!));
    var jsonObject = SoapUtils.parseXmlString2(form.ROW.definition, ['COMPONENT']);

    var formValues = await formService.listItemValue(new ListItemValueRequest(store.Server, store.SessionId, formValuesId!));
    
    var valuesMap = formValues.ROWS.reduce((acc: Map<string, any>, row: Rows) => { 
      acc.set(row.ITEMVALUE.formItem, row.ITEMVALUE); 
      return acc
    }, new Map<string, any>());

    setFormDefinition(jsonObject);
    setFormData(valuesMap);
  }, [selectedItem]);

  const updateData = (key: string, value: any) => {
    var modified_item = formData.get(key);
    modified_item.value = value;
    setFormData(new Map(formData.set(key, modified_item)));
  };

  useEffect(() => {
    loadFormCallback();
  }, [loadFormCallback]);

  if(formDefinition === null)
    return (
      <>
          <Flex direction={'column'}>
              <View>Form View</View>
          </Flex>
      </>
    );
  else
    return (
      <>
      <Flex direction={'column'} gap={50}>
        <View>
          <FormControl component={formDefinition.definition.gui.COMPONENT[0]} data={formData} updateData={updateData} />
        </View>
        {isSaveEnabled && (<View alignSelf={isSaveOnRightSide ? 'end' : 'start'}>
          <Button variant="cta" onPress={() => handleSaveForm()}>Save</Button>
        </View>)}
      </Flex>
      </>
    );
}

export const FormViewComponent = LayoutComponent(FormView);