import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FPAData, FPADataFactory, FPADataTypes } from "../../infra/protected/FPA/FPAData";
import { doSearchThunk, getContextPathThunk, loadTreeDataThunk, updateFiltersThunk } from "./finderThunks";
import { Items } from "../../services/soap/project/responses/ListFPAResponse";
import { AtollonEvent, publish } from "../../infra/events/Events";


export interface FinderState{
    treeData: FPAData[];
    searchData: FPAData[];
    backupData: FPAData[];
    childrenCache: any;
    searchCache: any;
    selectedItem: FPAData | null;
    selectedIndex: number;
    folderStatusMapping: any;
    projectStatusMapping: any;
    activityStatusMapping: any;
    searchFilter: any;
    showOverlay: boolean;
    showGlobalFinder: boolean;
    searchParent: FPAData | null;
    inContextMode: boolean;
    lastOpenedItem: FPAData | null;
    search_result_offset: number;
    search_result_total: number;
    isLoading: boolean;
    isSearching: boolean;
    loadingIndex: number;
    showDetails: boolean;
    treeFocus: boolean;
}

export const initialState: FinderState = {
    treeData: [],
    searchData: [],
    backupData: [],
    childrenCache: {},
    searchCache: {},
    selectedItem: null,
    selectedIndex: -1,
    folderStatusMapping: {},
    projectStatusMapping: {},
    activityStatusMapping: {},
    searchFilter: {},
    showOverlay: false,
    showGlobalFinder: false,
    searchParent: null,
    inContextMode: false,
    lastOpenedItem: null,
    search_result_offset: 0,
    search_result_total: 0,
    isLoading: false,
    isSearching: false,
    loadingIndex: -1,
    showDetails: false,
    treeFocus: true
} 

const find_remove_ids = (id:number, treeItems:FPAData[]):number[] => {
    var ids = [];

    ids = treeItems.filter((item) => +item.parent_id! === +id).map((item) => +item.id);

    var total: number[] = [];

    ids.forEach((item) => {
        total.push(item);
        total.push(...find_remove_ids(item, treeItems));
    });

    return total;
}

const set_context = (items:FPAData[], context:FPAData):FPAData[] => {
    var ids:number[] = [];

    var index = items.findIndex((item) => +item.id === +context.id);
    var visit:FPAData | undefined = items[index];
    while(visit){
        ids.push(+visit.id);
        if(visit.type !== FPADataTypes.FOLDER_TYPE){
            visit.isOpen = false;
            visit.page_offset = 0;
        }
        index = items.findIndex((item) => +item.id === +visit!.parent_id!);
        visit = items[index];
    }
  
    ids.push(...items.filter((item) => item.parent_id === context.id).map((item) => +item.id));
    return items.filter((item) => ids.includes(+item.id));
}

const add_separators_of_item_type = (items:FPAData[], parent: FPAData):FPAData[] => {
    var return_items:FPAData[] = [];
    var last_type = '';

    for(var item of items){
        if(item.type !== FPADataTypes.FOLDER && item.item_type_name !== last_type){
            const time_stamp = new Date().getTime();
            return_items.push(new FPAData(FPADataTypes.ITEM_TYPE, item.item_type_id+time_stamp, item.item_type_name,'','',0,'','','',0,'',parent.id, parent.type));
            last_type = item.item_type_name;
        }
        return_items.push(item);
    }

    return return_items;
}

export const finderSlice = createSlice({
    name: 'finder',
    initialState,
    reducers: {
        resetFinder: (state) => {
            state.treeData = [];
            state.childrenCache = {};
            state.selectedItem = null;
            state.searchFilter = {};
            state.showOverlay = false;
            state.searchParent = null;
            state.inContextMode = false;
            state.lastOpenedItem = null;
            state.search_result_offset = 0;
            state.search_result_total = 0;
            state.isLoading = false;
            state.isSearching = false;
            state.searchCache = {};
            state.loadingIndex = -1;
            state.showDetails = false;
        },
        changePageOffsetOfItems: (state, action: PayloadAction<{itemId:number, offset:number}>) => {
            var parent = state.treeData.find((item) => item.id === action.payload.itemId);
            if(parent){
                parent.page_offset = action.payload.offset;
            }
            if(state.lastOpenedItem && state.lastOpenedItem.id === action.payload.itemId){
                state.lastOpenedItem.page_offset = action.payload.offset;
            }
            if(state.selectedItem && state.selectedItem.id === action.payload.itemId){
                state.selectedItem.page_offset = action.payload.offset;
            }
        },
        loadFinder: (state, action: PayloadAction<{
                                        treeData:FPAData[], 
                                        selectedItem:FPAData | null, 
                                        showDetails:boolean, 
                                        inContextMode:boolean,
                                        lastOpenedItem:FPAData | null
                                    }>) => {
            state.treeData = action.payload.treeData;
            state.selectedItem = action.payload.selectedItem;
            state.showDetails = action.payload.showDetails;
            state.inContextMode = action.payload.inContextMode;
            if(action.payload.lastOpenedItem)
                state.lastOpenedItem = state.treeData.find( item => item.id == action.payload.lastOpenedItem!.id)!;
        },
        loadFolderTypes: (state, action: PayloadAction<{ folderTypes: any }>) => {
            const folder_types = Object.keys(action.payload.folderTypes).map((key:any) => action.payload.folderTypes[key]);
            var _items:FPAData[] = folder_types.map((item) => new FPAData(FPADataTypes.FOLDER_TYPE, item.id, item.name));
            _items.sort((a, b) => a.title < b.title ? -1 : 1);
            state.treeData = _items;
            state.selectedIndex = -1;
            state.selectedItem = null;
        },
        setSelectedIndex: (state, action: PayloadAction<number>) => {
            if(state.selectedItem && state.selectedItem.isSelected)
                state.selectedItem.isSelected = false;
            state.selectedIndex = action.payload;

            state.selectedItem = state.treeData[action.payload];
            state.selectedItem.isSelected = true;
        },
        setTreeFocus: (state, action: PayloadAction<boolean>) => {
            state.treeFocus = action.payload;
        },
        moveToParent: (state) => {
            if(state.selectedItem){
                var parent_index = state.treeData.findIndex((item) => item.id === state.selectedItem!.parent_id);
                if(parent_index >= 0){
                    state.treeData[state.selectedIndex] = {...state.treeData[state.selectedIndex], isSelected: false};
                    state.treeData[parent_index] = {...state.treeData[parent_index], isSelected: true};
                    state.selectedItem = state.treeData[parent_index];
                    state.selectedIndex = parent_index;
                }
            }
        },
        nextSelection: (state) => {
            if(state.selectedIndex === -1 && state.treeData.length === 0) return;
            
            var old_index = Math.max(state.selectedIndex, 0);
            var new_index = state.selectedIndex + 1;

            while(new_index < state.treeData.length && state.treeData[new_index].type === FPADataTypes.ITEM_TYPE)
                new_index += 1;

            if(new_index >= state.treeData.length)
                new_index = 0;

            state.treeData[old_index] = {...state.treeData[old_index], isSelected: false};
            state.treeData[new_index] = {...state.treeData[new_index], isSelected: true};
            state.selectedIndex = new_index;
            // state.selectedItem = state.treeData[new_index];
        },
        previousSelection: (state) => {
            if(state.selectedIndex === -1 && state.treeData.length === 0) return;

            var old_index = Math.max(state.selectedIndex, 0);
            var new_index = state.selectedIndex - 1;

            while(new_index >= 0 && state.treeData[new_index].type === FPADataTypes.ITEM_TYPE)
                new_index -= 1;

            if(new_index < 0)
                new_index = state.treeData.length - 1;

            state.treeData[old_index] = {...state.treeData[old_index], isSelected: false};
            state.treeData[new_index] = {...state.treeData[new_index], isSelected: true};
            state.selectedIndex = new_index;
            // state.selectedItem = state.treeData[new_index];
        },
        setSelectedItemAsSelectedIndex(state) {
            state.selectedItem = state.treeData[state.selectedIndex];
        },
        setSelectedIndexAsSelectedItem(state) {
            if(state.selectedItem){
                var index = state.treeData.findIndex((item) => item.id === state.selectedItem!.id);
                state.selectedIndex = index;
            }
        },
        setSelectedItem: (state, action: PayloadAction<{item: FPAData | null, show_context:boolean}>) => {
            var unselected_index = state.treeData.findIndex((item) => item.isSelected);
            var selected_index = state.treeData.findIndex((item) => item.id === action.payload.item?.id);

            if(unselected_index > -1){
                state.treeData[unselected_index].isSelected = false;
            }
            if(selected_index > -1){
                state.treeData[selected_index].isSelected = true;
                if(action.payload.show_context){
                    if(state.backupData.length === 0)
                        state.backupData = [...state.treeData];
                    var new_tree = set_context([...state.treeData], action.payload.item!);
                    state.treeData = [...new_tree];
                }
            }
            state.selectedItem = action.payload.item;
            state.selectedIndex = selected_index;
            state.inContextMode = true;
        },
        resetSelection: (state) => {
            state.selectedItem = null;
        },
        setMappings: (state, action: PayloadAction<{ folderStatusMapping: any, projectStatusMapping: any, activityStatusMapping: any }>) => {
            state.folderStatusMapping = action.payload.folderStatusMapping;
            state.projectStatusMapping = action.payload.projectStatusMapping;
            state.activityStatusMapping = action.payload.activityStatusMapping;
        },
        setSearchFilter: (state, action: PayloadAction<any>) => {
            state.searchFilter = action.payload;
        },
        unloadTreeData: (state, action: PayloadAction<{itemId:number}>) => {
            var ids = find_remove_ids(action.payload.itemId, state.treeData);
            state.treeData.forEach((item) => {
                if(ids.includes(+item.id)){
                    item.isOpen = false;
                    item.page_offset = 0;
                }
            });
            var parent = state.treeData.find((item) => item.id === action.payload.itemId);
            if(parent){
                parent.isOpen = false;
                parent.page_offset = 0;
            }
            state.treeData = state.treeData.filter((item) => !ids.includes(+item.id));
            if(state.lastOpenedItem && state.lastOpenedItem.id === action.payload.itemId){
                state.lastOpenedItem = null;
            }
            if(state.selectedItem && state.selectedItem.id === action.payload.itemId){
                state.selectedItem.page_offset = 0;
            }
        },
        showSearch: (state, action: PayloadAction<FPAData>) => {
            state.showOverlay = true;
            var find_item = state.treeData.find((item) => item.id === action.payload.id);
            if(find_item){
                state.searchParent = find_item;
            }
        },
        showGlobalSearch: (state) => {
            state.showOverlay = true;
            state.showGlobalFinder = true;
        },
        hideSearch: (state, action:PayloadAction<boolean>) => {
            state.showOverlay = false;
            state.showGlobalFinder = false;
            
            if(action.payload){
                if(state.searchParent)
                    state.searchParent.isFiltered = false;
                state.searchParent = null;
            }
            state.searchData = [];
            state.search_result_offset = 0;
            state.search_result_total = 0;
        },
        resetSearch: (state, action: PayloadAction<FPAData>) => {
            action.payload.isFiltered = false;
            if(action.payload.isOpen){
                action.payload.isOpen = false;
                var ids = find_remove_ids(+action.payload.id, state.treeData);
                state.treeData = state.treeData.filter((item) => !ids.includes(+item.id));
            }
            state.searchCache[action.payload.id] = null;
            if(state.lastOpenedItem && state.lastOpenedItem.id === action.payload.id)
                state.lastOpenedItem.page_offset = 0;
        },
        showSearchedSelected: (state, action: PayloadAction<FPAData>) => {
            if(state.selectedItem) 
                state.selectedItem.isSelected = false;

            var selected_item = state.treeData.find( item => item.id === action.payload.id);
            var selected_item_parent = state.treeData.find( item => item.id === action.payload.parent_id);

            if(!selected_item) 
                state.selectedItem = action.payload;
            else
                state.selectedItem = selected_item;

            
            state.selectedItem.isSelected = true;
            state.showDetails = true;

            if(!state.searchParent) 
                return;
                        
            var ids = find_remove_ids(state.searchParent!.id, state.treeData);
            state.treeData.forEach((item) => {
                if(ids.includes(+item.id)){
                    item.isOpen = false;
                }
            });

            state.treeData = state.treeData.filter((item) => !ids.includes(+item.id));
            // var insert_index = state.treeData.indexOf(state.searchParent!) + 1;
            var insert_index = state.treeData.findIndex((item) => item.id === state.searchParent!.id) + 1;

            state.treeData.splice(insert_index, 0, action.payload);

            if(selected_item_parent){
                state.selectedItem.node_level = selected_item_parent.node_level + 1;
                state.treeData[insert_index].node_level = selected_item_parent.node_level + 1;
            }

            state.searchParent!.isOpen = true;
            state.searchParent!.isFiltered = true;
            state.searchParent = null;
        },
        openContextMode: (state, action: PayloadAction<FPAData>) => {
            state.inContextMode = true;
            state.backupData = [...state.treeData];
            var new_tree = set_context([...state.treeData], action.payload);
            state.treeData = new_tree;
        },
        closeContextMode: (state) => {
            state.inContextMode = false;
            state.treeData = [...state.backupData];
            state.backupData = [];
        },
        openDetails: (state) => {
            state.showDetails = true;
        },
        closeDetails: (state) => {
            state.showDetails = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadTreeData.rejected, (state) => {
            state.isLoading = false;
        }).addCase(loadTreeData.pending, (state, action) => {
            state.isLoading = true;

            var parent = state.treeData.find((item) => item.id == action.meta.arg.itemId);
            if(parent){
                var children_count = state.treeData.filter((item) => item.parent_id === action.meta.arg.itemId).length;
                state.loadingIndex = state.treeData.indexOf(parent) + children_count;
            }
        }).addCase(loadTreeData.fulfilled, (state, action) => {
            state.isLoading = false;
            if(!action.payload.total_rows.ITEMS || action.payload.total_rows.ITEMS.length === 0) return;
            var parent = state.treeData.find((item) => item.id === action.meta.arg.itemId);
            
            if(!state.childrenCache[action.meta.arg.itemId]){
                state.childrenCache[action.meta.arg.itemId] = {};
            }

            const parent_node_level = parent ? parent.node_level : 0;

            state.childrenCache[action.meta.arg.itemId][action.meta.arg.offset] = [...action.payload.total_rows.ITEMS];
            var _items = parent && parent.isFiltered && state.searchCache[action.meta.arg.itemId] ? state.searchCache[action.meta.arg.itemId] 
            : action.payload.total_rows.ITEMS.map((item:Items) => FPADataFactory.createFromItem(
                item, 
                state.folderStatusMapping, 
                state.projectStatusMapping, 
                state.activityStatusMapping,
                action.meta.arg.itemId,
                action.meta.arg.itemType,
                parent_node_level + 1));
                
            if(parent)
                _items = add_separators_of_item_type(_items, parent!);

            if(action.meta.arg.itemId === 0){
                state.treeData = _items;
            } else {
                
                if(parent){
                    var insert_index = state.treeData.indexOf(parent) + 1;
                    if(parent.isOpen)
                        insert_index += parent.page_offset;
                    
                    // var rest_children = [ ...state.treeData];
                    var rest_children = action.meta.arg.offset == 0 ? state.treeData.filter((item) => item.parent_id !== action.meta.arg.itemId) : [ ...state.treeData];

                    rest_children.splice(insert_index, 0, ..._items);
                    state.treeData = [ ...rest_children ];
                    parent.isOpen = true;
                    state.lastOpenedItem = parent;
                    if(parent.type === FPADataTypes.FOLDER_TYPE && parent.count === 0){
                        parent.count = +action.payload.total_count;
                    }
                }
            }
        });

        builder.addCase(doSearch.pending, (state, action) => {
            state.isSearching = true;
            if(action.meta.arg.offset === 0) {
                state.searchData = [];
                state.search_result_offset = 0;
                state.search_result_total = 0;
            }
        }).addCase(doSearch.rejected, (state) => {
            state.isSearching = false;
        }).addCase(doSearch.fulfilled, (state, action) => {
            state.isSearching = false;
            if(!action.payload.total_rows.ITEMS){ state.searchData = []; return; }
            var result = action.payload.total_rows.ITEMS.map((item:Items) => FPADataFactory.createFromItem(
                item, 
                state.folderStatusMapping, 
                state.projectStatusMapping, 
                state.activityStatusMapping,
                action.meta.arg.itemId,
                action.meta.arg.itemType));
            
            state.searchData = [...state.searchData, ...result];
            state.search_result_total = action.payload.total_count;
            state.search_result_offset = action.meta.arg.offset + action.meta.arg.limit;

            state.searchCache[action.meta.arg.itemId!] = state.searchData;
        });

        builder.addCase(getContextPath.fulfilled, (state, action) => {
            if(!action.payload.count || action.payload.count === 0) return;
            if(action.meta.arg.isForSearch){
                var selected_index = state.treeData.findIndex((item) => +item.id === +state.selectedItem!.id);
                if(selected_index > -1){ return; } // if item already searched and found, do nothing

                var index = state.treeData.findIndex((item) => +item.id === +action.payload.LIST[0].CONTEXT.type);

                if(index === -1) return;

                var parent = state.treeData[index];
                
                parent.isOpen = false;
                parent.isFiltered = false;
                state.selectedItem!.node_level = parent.node_level + 1;
                state.searchCache[parent.id] = [state.selectedItem];
                state.treeData.splice(index + 1, 0, state.selectedItem!);
                state.selectedItem!.parent_id = parent.id;
                state.selectedItem!.parent_type = FPADataTypes.FOLDER_TYPE;
                var new_tree = set_context([...state.treeData], state.selectedItem!);
                state.treeData = new_tree;
                state.showDetails = true;
                state.inContextMode = true;
            } else {
                var items = action.payload.LIST.map((item) => FPADataFactory
                                                    .createFromContextItem(item.CONTEXT, 
                                                        state.folderStatusMapping, 
                                                        state.projectStatusMapping, 
                                                        state.activityStatusMapping));
                
                items.reverse();
                items.forEach((item, index) => {
                  item.isOpen = false;
                  item.isFiltered = false;
                  item.node_level = index;
                });
                state.treeData = items;
                state.selectedItem = items[items.length - 1];
                state.selectedItem.isSelected = true;
                state.selectedItem.isOpen = false;
                state.selectedItem.isFiltered = false;
                state.inContextMode = true;
                publish(AtollonEvent.INFO_CHANGED, state.selectedItem.title);
            }
        });

        builder.addCase(updateFilters.fulfilled, (state, action) => {
            //state.searchFilter = action.payload;
            console.log('Filters updated: ', action.payload);
        });
    }
});

export const loadTreeData = createAsyncThunk(
    'finder/loadData',
    loadTreeDataThunk,
    {
        condition: (arg, {getState}) => {
            var { finder } = getState() as any;
            return !finder.isLoading;
        }
    }
);

export const doSearch = createAsyncThunk(
    'finder/doSearch',
    doSearchThunk
);

export const getContextPath = createAsyncThunk(
    'finder/getContextPath',
    getContextPathThunk
);

export const updateFilters = createAsyncThunk(
    'finder/updateFilters',
    updateFiltersThunk
);

export const { 
    changePageOffsetOfItems,
    resetFinder,
    loadFinder,
    loadFolderTypes,
    setSelectedItem,
    setSelectedIndex,
    nextSelection,
    previousSelection,
    resetSelection,
    setMappings,
    setSearchFilter,
    unloadTreeData,
    showSearch,
    showGlobalSearch,
    hideSearch,
    resetSearch,
    showSearchedSelected,
    openContextMode,
    closeContextMode,
    openDetails,
    closeDetails,
    setSelectedItemAsSelectedIndex,
    setSelectedIndexAsSelectedItem,
    moveToParent,
    setTreeFocus
} = finderSlice.actions;
export default finderSlice.reducer;