import { useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Cell, Column, Row, TableView, TableBody, TableHeader, Flex, Content, View, Link, ProgressCircle, Checkbox } from '@adobe/react-spectrum';
import { useAsyncList } from 'react-stately';
import moment from 'moment';
import TaskListAddEdit from './TaskListAddEdit';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { ToastQueue } from '@react-spectrum/toast';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import { GetSchedulerSettingsRequest, SettingsItemType } from '../../../../services/soap/scheduler/requests/GetSchedulerSettingsRequest';
import { ListUserRequest } from '../../../../services/soap/access/requests/ListUserRequest';
import { ListTaskRequest } from '../../../../services/soap/scheduler/requests/ListTaskRequest';
import { DeleteTaskRequest } from '../../../../services/soap/scheduler/requests/DeleteTaskRequest';
import { UpdateTaskRequest } from '../../../../services/soap/scheduler/requests/UpdateTaskRequest';
import { CreateTaskRequest } from '../../../../services/soap/scheduler/requests/CreateTaskRequest';
import { generateTaskItem } from '../../../../services/soap/scheduler/requests/CreateTaskOptions';
import useComonentReload from '../../../../hooks/UseComponentReload';
import styles from './TaskListComponent.module.css';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';

interface TaskListProps {}

export interface RowData {
  ident: number;
  userId: string;
  title: string;
  entryType: string;
  entryTypeName: string;
  dealLine: string;
  solutionStatus: string;
  statusName: string;
  completed: boolean;

  headId: string;
}
interface Character {
  completed: string;
  entryTypeName: string;
  title: string;
  deadLine: string;
  statusName: string;
}

type typeTaskListSearchParam = {
  rangeStart: Date;
  rangeEnd: Date;
  userId: string;
  folderId: string;
  projectId?: string;
  activityId?: string;
  countOnly?: string;
  limit?: number;
  offset?: number;
  anyTime: string;
  includePast: string;
  dateOfInterest: string;
  groupUsers: string;
  multiUser: string;
};

type typeTaskListCreateParam = {
  activityId: string;
  projectId: string;
  folderId: string;
  userId: string;
  title: string;
  typeOfWorkId: string;
  solverId: string;
  solutionStatusId: string;
  u_item?: any;
};

function TaskList(props: TaskListProps) {
  const PAGE_SIZE: number = 50;

  let columnsData = [
    { name: '', key: 'completed', width: 25 },
    { name: 'Type', key: 'entryTypeName', width: 170 },
    { name: 'Title', key: 'title' },
    { name: 'Deadline', key: 'deadLine', width: 100 },
    { name: 'Status', key: 'statusName', width: 100 },
  ];

  let columnsDataMobile = [
    { name: '', key: 'completed', width: 25 },
    { name: 'Type', key: 'entryTypeName', width: 170 },
    { name: 'Title', key: 'title', width: 250 },
    { name: 'Deadline', key: 'deadLine', width: 130 },
    { name: 'Status', key: 'statusName', width: 160 },
  ];

  let defaultRowData: RowData = {
    ident: 0,
    userId: '',
    title: 'New task',
    entryType: '',
    entryTypeName: '',
    dealLine: '',
    solutionStatus: '',
    statusName: '',
    completed: false,
    headId: '',
  };

  const [reloadComponent] = useComonentReload();
  const { accessService, schedulerService, projectService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const [taskListData, setTaskListData] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any>([]);
  const [totalTaskListRec, setTotalTaskListRec] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<RowData>(defaultRowData);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showAddEditRow, setShowAddEditRow] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [typeofWorkList, setTypeofWorkList] = useState<any>([]);
  const [userData, setUserData] = useState<any>([]);
  const [statusListData, setStatusListData] = useState<any>(null);
  const dataRef = useRef<any>({});
  useEffect(() => {
    (async () => {
      setShowAddEditRow(false);
      const { LIST } = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, Number(selectedItem?.id)));
      //console.log('contextRespone', contextRespone);
      const folderID = selectedItem ? LIST[selectedItem.type === FPADataTypes.ACTIVITY ? 1 : 0]?.CONTEXT[selectedItem.type === FPADataTypes.FOLDER ? 'id' : 'parentId'] : undefined;
      //console.log('folderID', folderID);
      dataRef.current = { folderID };
      await getTypeofWorkList();
      await getStatusList();
      getUserCount().then((totalRecords: any) => gettUserData(totalRecords));
      setShowLoader(false);
    })();
  }, [selectedItem]);

  useEffect(() => {
    listTaskList.reload();
  }, [reloadComponent, selectedItem]);

  const getStatusList = async () => {
    const statusList = await schedulerService.getSchedulerSettings(new GetSchedulerSettingsRequest(store.Server, store.SessionId, '1', SettingsItemType.TASK_ITEM_TYPE, 50, 0));
    setStatusListData(statusList);
  };

  const getUserCount = async () => {
    // Retrieve selected item
    //console.log('selectedItem', selectedItem);

    // Call API to fetch tasklist line data
    let userList = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, undefined, undefined, '1'));

    //console.log('tasklistResp.ITEMS[0].ITEM.totalRecords', tasklistResp.ITEMS[0].ITEM.totalRecords);

    // If API call is successful
    if (userList.result === 'OK') {
      return userList.count;
    }
  };

  const gettUserData = async (totalRecords: number) => {
    let userResp = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, totalRecords));

    if (userResp.result === 'OK') {
      const formattedArray = userResp.USERS.map(({ USER }) => ({
        id: USER.id,
        name: `${USER.name1} ${USER.name3}`,
      }));
      setUserData(formattedArray);
    }
  };

  const getTypeofWorkList = async () => {
    let scheduleResp = await schedulerService.getSchedulerSettings(new GetSchedulerSettingsRequest(store.Server, store.SessionId, '4', SettingsItemType.TASK_ITEM_TYPE, 50, 0));
    //console.log('scheduleResp', scheduleResp);
    setTypeofWorkList(scheduleResp.ITEMS);
  };

  const getTaskListSearchParams = () => {
    const taskListSearchParams = {
      limit: 0,
      offset: 0,
      rangeStart: new Date('2000-01-01'),
      rangeEnd: new Date('2999-12-31'),
      userId: String(store.UserId),
      folderId: '',
      projectId: '',
      activityId: '',
      anyTime: '0',
      includePast: '0',
      countOnly: '1',
      dateOfInterest: '0',
      groupUsers: '1',
      multiUser: '1',
    } as typeTaskListSearchParam;

    switch (selectedItem?.type) {
      case FPADataTypes.ACTIVITY:
        taskListSearchParams.folderId = dataRef.current.folderID;
        taskListSearchParams.projectId = String(selectedItem?.parent_id);
        taskListSearchParams.activityId = String(selectedItem?.id);
        break;
      case FPADataTypes.PROJECT:
        taskListSearchParams.folderId = String(selectedItem?.parent_id);
        taskListSearchParams.projectId = String(selectedItem?.id);
        delete taskListSearchParams.activityId;
        break;
      case FPADataTypes.FOLDER:
        taskListSearchParams.folderId = String(selectedItem?.id);
        delete taskListSearchParams.activityId;
        delete taskListSearchParams.projectId;
        break;
    }

    return taskListSearchParams;
  };

  const getTaskListCount = async () => {
    // Retrieve selected item
    //console.log('selectedItem', selectedItem);

    // Call API to fetch tasklist line data

    const taskListSearchParams: typeTaskListSearchParam = getTaskListSearchParams();
    delete taskListSearchParams.limit;
    delete taskListSearchParams.offset;

    let taskListResp = await schedulerService.listTask(
      new ListTaskRequest(store.Server, store.SessionId, {
        ...taskListSearchParams,
      })
    );
    //console.log('tasklistResp Count', tasklistResp);
    if (taskListResp.result === 'OK') {
      setTotalTaskListRec(taskListResp.count != 0 ? taskListResp.count : 0);
      return taskListResp.count != 0 ? taskListResp.count : 0;
    }
  };

  let listTaskList = useAsyncList<Character>({
    async load({ cursor }) {
      //console.log('totalTaskListRec', totalTaskListRec);
      //console.log('cursor', cursor);

      if (cursor && Number(cursor) * PAGE_SIZE >= totalTaskListRec) {
        setShowLoader(false);
        return { items: [], cursor: undefined };
      }
      let totalRecords = await getTaskListCount();
      totalRecords = totalRecords ? Number(totalRecords) : 0;
      //console.log('totalRecords', totalRecords);
      let currentPage = cursor ? parseInt(cursor) : 0;
      //console.log('currentPage', currentPage);
      let offset = PAGE_SIZE * currentPage;
      //console.log('offset', offset);
      // if (isNaN(offset) || offset >= totalRecords) {
      //   setShowLoader(false);
      //   return { items: [], cursor: undefined };
      // }
      const taskListSearchParams: typeTaskListSearchParam = getTaskListSearchParams();
      let taskListResp = await schedulerService.listTask(
        new ListTaskRequest(store.Server, store.SessionId, {
          ...taskListSearchParams,
          limit: PAGE_SIZE,
          offset: offset,
          countOnly: undefined,
        })
      );

      //console.log('tasklistResp List', tasklistResp);

      if (taskListResp.result === 'OK') {
        //console.log('tasklistResp', tasklistResp);
        setTaskListData(taskListResp.ITEMS);
        let taskListData = taskListResp.ITEMS.map(item => ({
          id: item.ITEM.ident,
          userId: item.ITEM.userId,
          statusName: '',
          solutionStatus: item.ITEM.solutionStatus,
          deadLine: moment(item.ITEM.dateTo).format('MM/DD/YYYY'),
          title: item.ITEM.title,
          completed: item.ITEM.completed,
          entryType: item.ITEM.entryType,
          entryTypeName: item.ITEM.entryTypeName,
          headId: item.ITEM.headId,
        }));

        return updateTaskListData(taskListData).then(updatedData => {
          //console.log('updatedData', updatedData);
          setRowsData(updatedData);
          setShowLoader(false);

          return {
            items: updatedData,
            cursor: String(offset >= totalRecords ? undefined : currentPage + 1),
          };
        });
      } else {
        setShowLoader(false);
        return { items: [], cursor: undefined };
      }
    },
  });

  const updateTaskListData = async (TaskListData: any[]) => {
    const promises = TaskListData?.map(async (item: any) => ({
      ...item,
      statusName: await getStatusName(item),
    }));
    const updatedBillingLineData = await Promise.all(promises);
    return updatedBillingLineData;
  };

  const getStatusName = async (item: any) => {
    const statusList = statusListData?.ITEMS.length
      ? statusListData
      : await schedulerService.getSchedulerSettings(new GetSchedulerSettingsRequest(store.Server, store.SessionId, '1', SettingsItemType.TASK_ITEM_TYPE, 50, 0));
    return statusList.ITEMS.find((status: any) => status.ITEM.ident === item.solutionStatus)?.ITEM.title;
  };

  const handleRowClick = (rowId: any): void => {
    //const isSelectedRowNotEmpty = selectedRow && Object.keys(selectedRow).length > 0;
    //console.log('rowId', rowId.currentKey);
    //console.log('rowsData', rowsData);
    const rowData: any = rowsData.find((row: any) => row.id === rowId.currentKey);
    //console.log('rowData', rowData);
    setSelectedRow(rowData);
    setShowAddEditRow(true);
    setSelectedKeys(rowId);
  };

  const handleRowClose = (): void => {
    setSelectedRow(defaultRowData);
    setShowAddEditRow(false);
    handleUnselect();
  };

  const handleUnselect = () => {
    setSelectedKeys(new Set());
  };

  const getTaskListCreateParams = () => {
    const taskListCreateParams = {
      activityId: '',
      projectId: '',
      folderId: '',
      userId: String(store.UserId),
      title: '',
      typeOfWorkId: '',
      solverId: '',
      solutionStatusId: '',
    } as typeTaskListCreateParam;

    switch (selectedItem?.type) {
      case FPADataTypes.ACTIVITY:
        taskListCreateParams.folderId = dataRef.current.folderID;
        taskListCreateParams.projectId = String(selectedItem?.parent_id);
        taskListCreateParams.activityId = String(selectedItem?.id);
        break;
      case FPADataTypes.PROJECT:
        taskListCreateParams.folderId = String(selectedItem?.parent_id);
        taskListCreateParams.projectId = String(selectedItem?.id);
        break;
      case FPADataTypes.FOLDER:
        taskListCreateParams.folderId = String(selectedItem?.id);
        break;
    }

    return taskListCreateParams;
  };

  const addRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('addRecord', record);

      // let taskStatus = await schedulerService.getSchedulerSettings(new GetSchedulerSettingsRequest(store.Server, store.SessionId, '1', SettingsItemType.TASK_ITEM_TYPE, 50, 0));

      const taskListCreateParams: typeTaskListCreateParam = getTaskListCreateParams();
      taskListCreateParams.title = record.title;
      taskListCreateParams.typeOfWorkId = record.entryType;
      taskListCreateParams.solverId = record.userId;
      taskListCreateParams.solutionStatusId = '131817081';
      taskListCreateParams.u_item = {};

      let { result, EXCEPTION } = await schedulerService.createTask(
        new CreateTaskRequest(
          store.Server,
          store.SessionId,
          generateTaskItem(
            taskListCreateParams.activityId,
            taskListCreateParams.projectId,
            taskListCreateParams.folderId,
            taskListCreateParams.userId,
            taskListCreateParams.title,
            taskListCreateParams.typeOfWorkId,
            taskListCreateParams.solverId,
            taskListCreateParams.solutionStatusId,
            taskListCreateParams.u_item
          )
        )
      );

      //console.log('addRecord result', result);

      if (result === 'OK') {
        //await getTaskListCount();
        handleRowClose();
        listTaskList.reload();
        ToastQueue.positive('Record saved successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error: any) {
      ToastQueue.negative(error.message, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const updateRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('TaskListData', TaskListData);
      //console.log('update record', record);
      let itemRow = taskListData.find((row: any) => row.ITEM.ident == record.id);
      //console.log('itemRow', itemRow);

      let { result, EXCEPTION } = await schedulerService.updateTask(
        new UpdateTaskRequest(store.Server, store.SessionId, {
          ...itemRow.ITEM,
          entryType: record.entryType,
          userId: record.userId,
          title: record.title,
          USER: {
            id: record.userId,
          },
        })
      );

      // console.log('result', result);
      if (result === 'OK') {
        handleRowClose();
        listTaskList.reload();
        ToastQueue.positive('Record updated successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const deleteRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('record', record);

      let result = await schedulerService.deleteTask(new DeleteTaskRequest(store.Server, store.SessionId, record.id, false, record.headId));

      //console.log('result', result);
      if (result.result === 'OK') {
        //await getTaskListCount();
        handleRowClose();
        listTaskList.reload();
        ToastQueue.positive('Record deleted successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            Task List
          </Content>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <Link
                isQuiet
                onPress={e => {
                  defaultRowData.userId = String(store.UserId);
                  setSelectedRow(defaultRowData);
                  handleUnselect();
                  setShowAddEditRow(true);
                }}
              >
                <i className="bi bi-plus fs-5">
                  <View UNSAFE_className={styles.icon_add_text}>Add Task </View>
                </i>
              </Link>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            <TableView
              aria-label="tasklist line"
              onSelectionChange={handleRowClick}
              selectionMode="single"
              selectedKeys={selectedKeys}
              selectionStyle="highlight"
              width={{ base: '100%', L: '100%', M: '100%' }}
              minHeight={'80px'}
              maxHeight={{ base: '1000px', L: '450px' }}
              marginBottom={'size-250'}
            >
              <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                {(column: any) => (
                  <Column showDivider width={column?.width}>
                    {column.name}
                  </Column>
                )}
              </TableHeader>
              <TableBody items={listTaskList.items} loadingState={listTaskList.loadingState} onLoadMore={listTaskList.loadMore}>
                {item => (
                  <Row>
                    {columnKey =>
                      columnKey === 'completed' ? (
                        <Cell>
                          <Checkbox isDisabled isSelected={(item as any)[columnKey]} onChange={() => console.log('check')} />
                        </Cell>
                      ) : (
                        <Cell>{(item as any)[columnKey]}</Cell>
                      )
                    }
                  </Row>
                )}
              </TableBody>
            </TableView>
          </Flex>
          {showAddEditRow && (
            <View paddingBottom={isMobile ? 70 : 0}>
              <TaskListAddEdit
                id={String(selectedItem?.id)}
                defaultRowData={defaultRowData}
                typeofWorkList={typeofWorkList}
                userData={userData}
                selectedRowData={selectedRow}
                handleRowClose={handleRowClose}
                addRecord={addRecord}
                updateRecord={updateRecord}
                deleteRecord={deleteRecord}
                styles={styles}
              />
            </View>
          )}
        </Flex>
      </Flex>
    );
  }
}

export const TaskListComponent = LayoutComponent(TaskList);
