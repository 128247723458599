import React, { useEffect, useState } from 'react';
import { ActionButton, Checkbox, ComboBox, Content, Dialog, DialogTrigger, Flex, Item, Link, NumberField, Picker, View } from '@adobe/react-spectrum';
import { TrashIcon, AlertIconFill } from '../../Icons/IconsLib';
import { useBeforeRender } from '../../../../hooks/useBeforeRender';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { RowData } from './TimesheetLineComponent';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';

interface TimesheetLineAddEditProps {
  selectedItem: any;
  id?: string;
  defaultRowData: {};
  selectedRowData: RowData;
  typeofWorkList: any[];
  userData: any[];
  handleRowClose: () => void;
  addRecord: (record: any) => void;
  updateRecord: (record: any) => void;
  deleteRecord: (record: any) => void;
  styles: any;
}

const TimesheetLineAddEdit: React.FC<TimesheetLineAddEditProps> = ({
  selectedItem,
  id,
  defaultRowData,
  selectedRowData,
  typeofWorkList,
  userData,
  handleRowClose,
  addRecord,
  updateRecord,
  deleteRecord,
  styles,
}) => {
  const { isMobile } = useViewInfo();
  const [selectedRow, setSelectedRow] = useState<RowData>(selectedRowData || defaultRowData);

  const [validationMessages, setValidationMessages] = useState<any>({ workerName: '', typeOfWork: '', entryDate: '', hours: '', mintues: '', chargeable: '' });

  /** Code to hide Observer Error */
  useBeforeRender(() => {
    window.addEventListener('error', e => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) resizeObserverErr.className = 'hide-resize-observer';
        if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'hide-resize-observer';
      }
    });
  }, []);

  useEffect(() => {
    const isDefaultRow = selectedRowData.ident === 0;
    setValidationMessages({
      workerName: '',
      typeOfWork: isDefaultRow ? 'required' : '',
      entryDate: '',
      hours: '',
      mintues: '',
      chargeable: '',
    });

    setSelectedRow(selectedRowData || defaultRowData);
    if (isDefaultRow) setSelectedRow(prev => ({ ...prev, hours: '1', minutes: '0' }));
  }, [id, selectedRowData]);

  const addPermision = () => {
    let addFlag = false;
    switch (selectedItem?.type) {
      case FPADataTypes.ACTIVITY:
        addFlag = true;
        break;
      case FPADataTypes.PROJECT:
      case FPADataTypes.FOLDER:
        if (selectedRow.ident !== 0) {
          addFlag = true;
        } else {
          addFlag = false;
        }
        break;
    }

    return addFlag;
  };

  const validateFields = () => {
    //console.log('validationMessages ', validationMessages);
    const fields = [
      { key: 'workerName', value: selectedRow.userId },
      { key: 'typeOfWork', value: selectedRow.typeOfWorkId },
      { key: 'entryDate', value: selectedRow.entryDate },
      { key: 'hours', value: selectedRow.hours },
    ];

    const isValid = fields.every(({ key, value }) => {
      const isFieldValid = validationMessages[key] === '' && value !== '' && value !== undefined;
      if (!isFieldValid) setValidationMessages((prev: any) => ({ ...prev, [key]: 'required' }));
      return isFieldValid;
    });

    return isValid;
  };

  const validateAndSubmit = () => {
    if (!validateFields()) return;
    const action = selectedRowData && Number(selectedRowData.ident) !== 0 ? updateRecord : addRecord;
    //console.log('selectedRow', selectedRow); // Optional: Only if needed for both cases
    action(selectedRow);
  };

  return (
    <>
      {isMobile ? (
        <Flex direction="column" marginTop={'size-200'}>
          <View>
            <ComboBox
              label="Worker"
              isRequired
              width={'100%'}
              defaultItems={userData}
              selectedKey={selectedRow.userId}
              onSelectionChange={value => {
                const worker = userData.find((item: any) => item.id === value);
                if (worker?.name) setSelectedRow((prev: any) => ({ ...prev, userId: value, workerName: worker.name }));
              }}
              validationState={validationMessages.workerName !== '' ? 'invalid' : 'valid'}
            >
              {(item: any) => <Item key={item.id}>{item.name}</Item>}
            </ComboBox>
          </View>

          <View>
            <Picker
              label="Type of work"
              isRequired
              width={'100%'}
              items={addPermision() ? typeofWorkList : []}
              onSelectionChange={value => {
                setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                const workerName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, workerName }));
              }}
              selectedKey={selectedRow.typeOfWorkId}
              validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
            >
              {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
            </Picker>
          </View>
          <View>
            <label className="A-HlBa_spectrum-FieldLabel">
              Date ​
              <svg className="yxBNXG_spectrum-Icon yxBNXG_spectrum-UIIcon-Asterisk A-HlBa_spectrum-FieldLabel-requiredIcon" focusable="false" aria-hidden="true" role="img">
                <path d="M6.573 6.558c.056.055.092.13 0 .204l-1.148.74c-.093.056-.13.02-.167-.073L3.832 4.947l-1.87 2.055c-.02.037-.075.074-.13 0l-.889-.926c-.092-.055-.074-.111 0-.167l2.111-1.76-2.408-.906c-.037 0-.092-.074-.055-.167l.63-1.259a.097.097 0 0 1 .166-.036l2.111 1.37.13-2.704a.097.097 0 0 1 .111-.11L5.277.54c.092 0 .11.037.092.13l-.722 2.647 2.444-.74c.056-.038.111-.038.148.073l.241 1.37c.019.093 0 .13-.074.13l-2.556.204z"></path>
              </svg>
            </label>
            <DatePickerComponent
              className="YO3Nla_spectrum-Textfield-input YO3Nla_i18nFontFamily"
              id="datepickermobile"
              width={'100%'}
              value={new Date(selectedRow.entryDate)}
              onChange={(value: any) => {
                //console.log('datepicker', value.value);
                setSelectedRow(prev => ({ ...prev, entryDate: String(value.value) }));
              }}
            />
          </View>
          <View>
            <NumberField
              width={'100%'}
              value={Number(selectedRow.hours)}
              defaultValue={Number(selectedRow.hours)}
              isRequired
              minValue={1}
              label="Hours"
              onChange={value => {
                const message = isNaN(value) || value <= 0 ? 'Hours must be greater than 0' : '';
                setValidationMessages((prev: any) => ({ ...prev, hours: message }));
                setSelectedRow(prev => ({ ...prev, hours: String(value) }));
              }}
              validationState={validationMessages.hours !== '' ? 'invalid' : 'valid'}
            />
            <NumberField
              width={'100%'}
              value={Number(selectedRow.minutes)}
              step={5}
              isRequired
              minValue={0}
              maxValue={60}
              label="Minutes"
              onChange={value => {
                const message = isNaN(value) || value <= 0 ? 'Minutes must be greater than 0' : '';
                setValidationMessages((prev: any) => ({ ...prev, minutes: message }));
                if (value > 59) {
                  setSelectedRow(prev => ({ ...prev, minutes: '0', hours: String(Number(prev.hours) + 1) }));
                } else setSelectedRow(prev => ({ ...prev, minutes: String(value) }));
              }}
              validationState={validationMessages.hours !== '' ? 'invalid' : 'valid'}
            />
          </View>
          <View>
            <label className="A-HlBa_spectrum-FieldLabel">Chargeable</label>
            <Checkbox isSelected={selectedRow.chargeable === 'Yes'} onChange={value => setSelectedRow(prev => ({ ...prev, chargeable: value ? 'Yes' : 'No' }))} />
          </View>
        </Flex>
      ) : (
        <table className="spectrum-Table" style={{ marginTop: '20px' }}>
          <thead className="spectrum-Table-head">
            <tr>
              <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <ComboBox
                    width={'100%'}
                    label=""
                    defaultItems={userData}
                    selectedKey={selectedRow.userId}
                    onSelectionChange={value => {
                      const worker = userData.find((item: any) => item.id === value);
                      if (worker?.name) setSelectedRow((prev: any) => ({ ...prev, userId: value, workerName: worker.name }));
                    }}
                    validationState={validationMessages.workerName !== '' ? 'invalid' : 'valid'}
                  >
                    {(item: any) => <Item key={item.id}>{item.name}</Item>}
                  </ComboBox>
                </span>
              </th>
              <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <Picker
                    label=""
                    width={'100%'}
                    items={addPermision() ? typeofWorkList : []}
                    onSelectionChange={value => {
                      setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                      const workerName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                      setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, workerName }));
                    }}
                    selectedKey={selectedRow.typeOfWorkId}
                    validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
                  >
                    {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
                  </Picker>
                </span>
              </th>
              <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                <div style={{ marginTop: navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome') ? '-5px' : '0' }}>
                  <DatePickerComponent
                    id="datepicker"
                    className="YO3Nla_spectrum-Textfield-input YO3Nla_i18nFontFamily"
                    value={new Date(selectedRow.entryDate)}
                    onChange={(value: any) => {
                      //console.log('datepicker', value.value);
                      setSelectedRow(prev => ({ ...prev, entryDate: String(value.value) }));
                    }}
                  />
                </div>
              </th>
              <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <table width={'100%'}>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width={'55%'} title="Enter Hours">
                          <NumberField
                            width={'100%'}
                            value={Number(selectedRow.hours)}
                            hideStepper
                            isRequired
                            minValue={1}
                            label=""
                            onChange={value => {
                              const message = isNaN(value) || value <= 0 ? 'Hours must be greater than 0' : '';
                              setValidationMessages((prev: any) => ({ ...prev, hours: message }));
                              setSelectedRow(prev => ({ ...prev, hours: String(value) }));
                            }}
                            validationState={validationMessages.hours !== '' ? 'invalid' : 'valid'}
                          />
                        </td>
                        <td title="Enter Minutes">
                          <NumberField
                            width={'100%'}
                            value={Number(selectedRow.minutes)}
                            hideStepper
                            isRequired
                            minValue={0}
                            maxValue={60}
                            label=""
                            onChange={value => {
                              const message = isNaN(value) || value <= 0 ? 'Minutes must be greater than 0' : '';
                              setValidationMessages((prev: any) => ({ ...prev, minutes: message }));
                              if (value > 59) {
                                setSelectedRow(prev => ({ ...prev, minutes: '0', hours: String(Number(prev.hours) + 1) }));
                              } else setSelectedRow(prev => ({ ...prev, minutes: String(value) }));
                            }}
                            validationState={validationMessages.mintues !== '' ? 'invalid' : 'valid'}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
              </th>
              <th style={{ width: '9%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <Checkbox isSelected={selectedRow.chargeable === 'Yes'} onChange={value => setSelectedRow(prev => ({ ...prev, chargeable: value ? 'Yes' : 'No' }))} marginStart={'size-125'} />
                </span>
              </th>
            </tr>
          </thead>
        </table>
      )}

      <Flex direction={{ base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-175'} marginTop={'size-200'}>
        <Flex>
          {Number(selectedRowData.ident) !== 0 && (
            <Flex>
              <DialogTrigger isDismissable type="modal">
                <ActionButton UNSAFE_className={styles.btn_delete}>Remove timesheet</ActionButton>
                {close => (
                  <Dialog size="S">
                    <Flex UNSAFE_className={styles.delete_pop_up_parent}>
                      <Content UNSAFE_className={styles.delete_pop_up_header}>Remove timesheet </Content>
                      <View UNSAFE_className={styles.delete_pop_up_line}></View>
                      <View UNSAFE_className={styles.delete_pop_up_warning_text}>Are you sure, you want to remove timesheet?</View>
                      <View UNSAFE_className={styles.buttons_parent}>
                        <View UNSAFE_className={styles.button_cancel}>
                          <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
                            No, keep it.
                          </Link>
                        </View>
                        <View UNSAFE_className={styles.button_confirm}>
                          <div className={styles.trash_icon}>
                            <TrashIcon size={32} />
                          </div>
                          <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(selectedRow)}>
                            Yes, remove!
                          </Link>
                        </View>
                      </View>
                      <View UNSAFE_className={styles.ellipse_div}>
                        <View UNSAFE_className={styles.delete_alert_icon}>
                          <AlertIconFill size={72} />
                        </View>
                      </View>
                    </Flex>
                  </Dialog>
                )}
              </DialogTrigger>
            </Flex>
          )}
        </Flex>
        <Flex direction={{ base: 'row', L: 'row' }} gap={'size-200'} marginBottom={'size-225'}>
          <Flex>
            <Link UNSAFE_className={styles.btn_cancel} isQuiet onPress={handleRowClose}>
              Cancel
            </Link>
          </Flex>

          <Flex>
            <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={() => validateAndSubmit()}>
              Confirm time
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default TimesheetLineAddEdit;
