import React, { useEffect, useState } from 'react';
import { ActionButton, ComboBox, Content, Dialog, DialogTrigger, Flex, Item, Link, Picker, TextField, View } from '@adobe/react-spectrum';
import { TrashIcon, AlertIconFill } from '../../Icons/IconsLib';
import { useBeforeRender } from '../../../../hooks/useBeforeRender';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { RowData } from './TaskListComponent';

interface TaskListAddEditProps {
  id?: string;
  defaultRowData: {};
  selectedRowData: RowData;
  typeofWorkList: any[];
  userData: any[];
  handleRowClose: () => void;
  addRecord: (record: any) => void;
  updateRecord: (record: any) => void;
  deleteRecord: (record: any) => void;
  styles: any;
}

const TaskListAddEdit: React.FC<TaskListAddEditProps> = ({ id, defaultRowData, selectedRowData, typeofWorkList, userData, handleRowClose, addRecord, updateRecord, deleteRecord, styles }) => {
  const { isMobile } = useViewInfo();
  const [selectedRow, setSelectedRow] = useState<RowData>(selectedRowData || defaultRowData);

  const [validationMessages, setValidationMessages] = useState<any>({ workerName: '', entryType: '', title: '' });

  /** Code to hide Observer Error */
  useBeforeRender(() => {
    window.addEventListener('error', e => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) resizeObserverErr.className = 'hide-resize-observer';
        if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'hide-resize-observer';
      }
    });
  }, []);

  useEffect(() => {
    //console.log('selectedRowData', selectedRowData);
    const isDefaultRow = selectedRowData.ident === 0;
    setValidationMessages({
      workerName: '',
      entryType: isDefaultRow ? 'required' : '',
      title: '',
    });

    setSelectedRow(selectedRowData || defaultRowData);
    if (isDefaultRow) setSelectedRow(prev => ({ ...prev, title: 'New task' }));
  }, [id, selectedRowData]);

  const validateFields = () => {
    //console.log('validationMessages ', validationMessages);
    const fields = [
      { key: 'workerName', value: selectedRow.userId },
      { key: 'entryType', value: selectedRow.entryType },
      { key: 'title', value: selectedRow.title },
    ];

    const isValid = fields.every(({ key, value }) => {
      const isFieldValid = validationMessages[key] === '' && value !== '' && value !== undefined;
      if (!isFieldValid) setValidationMessages((prev: any) => ({ ...prev, [key]: 'required' }));
      return isFieldValid;
    });

    return isValid;
  };

  const validateAndSubmit = () => {
    if (!validateFields()) return;
    const action = selectedRowData && Number(selectedRowData.ident) !== 0 ? updateRecord : addRecord;
    //console.log('selectedRow', selectedRow); // Optional: Only if needed for both cases
    action(selectedRow);
  };

  return (
    <>
      {isMobile ? (
        <Flex direction="column" marginTop={'size-200'}>
          <View>
            <Picker
              label="Type"
              isRequired
              width={'100%'}
              items={typeofWorkList}
              onSelectionChange={value => {
                setValidationMessages((prev: any) => ({ ...prev, entryType: '' }));
                setSelectedRow((prev: any) => ({ ...prev, entryType: value }));
              }}
              selectedKey={selectedRow.entryType}
              validationState={validationMessages.entryType !== '' ? 'invalid' : 'valid'}
            >
              {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
            </Picker>
          </View>
          <View>
            <TextField
              label="Title"
              isRequired
              width={'100%'}
              value={selectedRow.title}
              validationState={validationMessages.title !== '' ? 'invalid' : 'valid'}
              onChange={value => {
                setSelectedRow((prev: any) => ({ ...prev, title: value }));
                value === '' ? (validationMessages.title = 'required') : (validationMessages.title = '');
              }}
            ></TextField>
          </View>
          <View>
            <ComboBox
              isRequired
              width={'100%'}
              label="Assigned to"
              defaultItems={userData}
              selectedKey={selectedRow.userId}
              onSelectionChange={value => {
                setSelectedRow((prev: any) => ({ ...prev, userId: value }));
              }}
              validationState={validationMessages.workerName !== '' ? 'invalid' : 'valid'}
            >
              {(item: any) => <Item key={item.id}>{item.name}</Item>}
            </ComboBox>
          </View>
        </Flex>
      ) : (
        <table className="spectrum-Table" style={{ marginTop: '20px' }}>
          <thead className="spectrum-Table-head">
            <tr>
              <th style={{ width: '3.5%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">&nbsp;</span>
              </th>
              <th style={{ width: '8%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <Picker
                    label=""
                    width={'100%'}
                    items={typeofWorkList}
                    onSelectionChange={value => {
                      setValidationMessages((prev: any) => ({ ...prev, entryType: '' }));
                      setSelectedRow((prev: any) => ({ ...prev, entryType: value }));
                    }}
                    selectedKey={selectedRow.entryType}
                    validationState={validationMessages.entryType !== '' ? 'invalid' : 'valid'}
                  >
                    {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
                  </Picker>
                </span>
              </th>
              <th style={{ width: '24%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <TextField
                    width={'100%'}
                    value={selectedRow.title}
                    validationState={validationMessages.title !== '' ? 'invalid' : 'valid'}
                    onChange={value => {
                      setSelectedRow((prev: any) => ({ ...prev, title: value }));
                      value === '' ? (validationMessages.title = 'required') : (validationMessages.title = '');
                    }}
                  ></TextField>
                </span>
              </th>
              <th colSpan={2} style={{ width: '10%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  {/* <label htmlFor="assigedTo" style={{ alignSelf: 'flex-start', paddingRight: '5px' }}>
                    Assigned to:
                  </label> */}
                  <ComboBox
                    id="assigedTo"
                    width={'100%'}
                    label=""
                    defaultItems={userData}
                    selectedKey={selectedRow.userId}
                    onSelectionChange={value => {
                      setSelectedRow((prev: any) => ({ ...prev, userId: value }));
                    }}
                    validationState={validationMessages.workerName !== '' ? 'invalid' : 'valid'}
                  >
                    {(item: any) => <Item key={item.id}>{item.name}</Item>}
                  </ComboBox>
                </span>
              </th>
            </tr>
          </thead>
        </table>
      )}

      <Flex direction={{ base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-175'} marginTop={'size-200'} marginBottom={'size-225'}>
        <Flex>
          {Number(selectedRowData.ident) !== 0 && (
            <Flex>
              <DialogTrigger isDismissable type="modal">
                <ActionButton UNSAFE_className={styles.btn_delete}>Remove task</ActionButton>
                {close => (
                  <Dialog size="S">
                    <Flex UNSAFE_className={styles.delete_pop_up_parent}>
                      <Content UNSAFE_className={styles.delete_pop_up_header}>Remove task </Content>
                      <View UNSAFE_className={styles.delete_pop_up_line}></View>
                      <View UNSAFE_className={styles.delete_pop_up_warning_text}>Are you sure, you want to remove task?</View>
                      <View UNSAFE_className={styles.buttons_parent}>
                        <View UNSAFE_className={styles.button_cancel}>
                          <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
                            No, keep it.
                          </Link>
                        </View>
                        <View UNSAFE_className={styles.button_confirm}>
                          <div className={styles.trash_icon}>
                            <TrashIcon size={32} />
                          </div>
                          <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(selectedRow)}>
                            Yes, remove!
                          </Link>
                        </View>
                      </View>
                      <View UNSAFE_className={styles.ellipse_div}>
                        <View UNSAFE_className={styles.delete_alert_icon}>
                          <AlertIconFill size={72} />
                        </View>
                      </View>
                    </Flex>
                  </Dialog>
                )}
              </DialogTrigger>
            </Flex>
          )}
        </Flex>
        <Flex direction={{ base: 'row', L: 'row' }} gap={'size-200'}>
          <Flex>
            <Link UNSAFE_className={styles.btn_cancel} isQuiet onPress={handleRowClose}>
              Cancel
            </Link>
          </Flex>

          <Flex>
            <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={() => validateAndSubmit()}>
              Confirm task
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default TaskListAddEdit;
